export const BannerData = {
  description:
    "It thrives across HBKU's six colleges and three national research institutes to advance science and technology across various disciplines and to find solutions for many local and global challenges.",
  title: "Research is integral to Hamad Bin Khalifa University (HBKU)'s mission to help build human capacity in Qatar.",
  poster : '/uploads/mainBannerPoster1.png',
  src : '/uploads/research_welcome.mp4'
}

export const BannerDataAr = {
  lang: 'ar',
  description:
    "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. ولذلك يتم استخدام",
  title: "طريقة لوريم إيبسوم لأنها تعطي توزيعاَ طبيعياَ -إلى حد ما- للأحرف عوضاً عن استخدامهنا يوجد محتوى نصي، هنا يوجد محتوى نصي فتجعلها تبدو (أي الأحرف) وكأنها نص مقروء. العديد من برامح النشر المكتبي وبرامح تحرير صفحات الويب تستخدم لوريم إيبسوم بشكل إفتراضي كنموذج",
  poster : '/uploads/mainBannerPoster1ar.png',
  src : '/uploads/research_welcome.mp4'
}