import '../../assets/scss/GridCarousel.scss'
import { Component } from 'react'


function GridItem(props) {
  return (
    <div className={`grid-carousel-item${props.active ? ' active' : ''}`}>
      <img src={props.image} alt={props.title} />
      <div className='grid-carousel-item-overlay'></div>
      <div className='grid-carousel-item-container'>
        <div className='grid-carousel-item-header'>
          <div>
          <span className={`custom-badge me-2`}>Research</span>
            {/* {props.tag.map((e, k) => (
              <span className={`custom-badge me-2 ${e.classN}`} key={k}>
                {e.text}
                Research
                </span>
            ))} */}
          </div>
          {/* <button onClick={props.change}>
            {props.active ? <i className='far fa-image' /> : <i className='fas fa-play' />}
          </button> */}
        </div>
        <div className='grid-carousel-item-footer'>
          <span className='grid-carousel-item-name'>
          <a href={props.url} target={'_blank'} rel="noreferrer" >{props.title}</a>
          </span>
          <span className='grid-carousel-item-description'>{props.description}</span>
        </div>
      </div>
    </div>
  )
}

// function Filter(props) {
//   if (props.noFilter) return <></>
//   const field = props.field
//   const fields = []
//   for (let i = 0; i < props.fields.length; i++) {
//     let e = props.fields[i]
//     if (!fields.includes(e[field])) fields.push(e[field])
//   }

//   return (
//     <div className='filter start sm no-marker'>
//       <button onClick={() => props.handler('')} className={props.selected === '' ? 'active' : ''}>
//         {props.lang === 'ar' ? 'الكل' : 'All'}
//       </button>
//       {fields.map((e, k) => (
//         <button
//           className={props.selected === e ? 'active' : ''}
//           onClick={() => props.handler(e)}
//           key={k}>
//           {e}
//         </button>
//       ))}
//     </div>
//   )
// }

// function SortBy(props) {
//   return (
//     <div className='sorter-container'>
//       <div className='sorter d-inline'>
//         {props.title}
//         <div className='sort-list'>
//           {['Date', 'Type', 'Name', 'Place'].map((e, k) => (
//             <button
//               name={e}
//               key={k}
//               onClick={props.handler}
//               className={props.selected === e ? 'active' : ''}>
//               {e}
//             </button>
//           ))}
//         </div>
//       </div>
//     </div>
//   )
// }
class GridCarousel extends Component {
  constructor(props) {
    super(props)
    this.state = { active: 0, selected: '', sortBy: '' }
    this.timer = null
  }

  // next = () => this.setState({ active: (this.state.active + 1) % this.props.items.length })

  // change = n => {
  //   this.setState({ active: n })
  //   clearInterval(this.timer)
  //   this.timer = setInterval(this.next, 10000)
  // }

  componentDidMount() {
    this.timer = setInterval(this.next, 10000)
  }
  componentWillUnmount() {
    clearInterval(this.timer)
  }
  setSelected = val => this.setState({ selected: val })

  setSortBy = event => this.setState({ sortBy: event.target.name })
  render() {
    const { lang } = this.props
    return (
      <>
      <section id={this.props.itemID} dir={lang === 'ar' ? 'rtl' : 'ltr'} className='grid-carousel m-wrap'>
        <div className='container'>
          <div className='row'>
            <div className='col'>
               <h5 className="text-secondary">{this.props.subtitle}</h5>
              <h2>{this.props.title}</h2>
              <p className="p-b-2">{this.props.description}</p>
            </div>
            <div className='col text-end'>
              {/* <SortBy
                selected={this.state.sortBy}
                handler={this.setSortBy}
                title='Filter upcoming event by '
              /> */}
            </div>
          </div>
          <p>{this.props.description}</p>
          {/* <Filter
            lang={this.props.lang}
            noFilter={this.props.noFilter}
            handler={e => this.setSelected(e)}
            selected={this.state.selected}
            field='field'
            fields={[
              { field: 'Workshop' },
              { field: 'Webinar' },
              { field: 'Colloiquilm' },
              { field: 'Conference' },
              { field: 'Academic' },
            ]}
          /> */}
          <div className='grid-carousel-container'>
            {this.props.items.map((e, k) => (
              <GridItem
                change={() => this.change(k)}
                active={k === this.state.active}
                {...e}
                key={k}
              />
            ))}
          </div>
          <div className='text-center'>
            {/^https?:\/\//.test(this.props.viewMore)
          ? <a href={this.props.viewMore} target={'_blank'} rel="noreferrer" className='action'>View More</a>
          : <a href={this.props.viewMore} rel="noreferrer" className='action'>View More</a>
          // : <Link  to={this.props.viewMore} className='action'>View More</Link>
          }
          </div>
        </div>
      </section>
      
      </>
      

      
    )
  }
}
export default GridCarousel
