import React from 'react'
import FacilitiesBlock from '../components/FacilitiesBlock'
import Navigation from '../components/Navigation'
import Footer from '../components/Footer'
import { footerEn /* footerAr*/ } from '../data/footer'
import { navigationTop, navigationButtom } from '../params'
import '../assets/scss/Funding.scss'

const navigationIntern = [
  // { text: 'Facilities', hash: '#FacilitiesBlock', isActive: true },

]

const crumb = [
  { link: '//', text: 'Home' },
  { link: '/', text: 'Research' },
  { link: '/research/facilities', text: 'Facilities' },
]


function FacilitiesServices(props) {
  document.title = 'Research Contact Us - HBKU'
  const headerData = {
    title: 'Research at HBKU',
    crumb: crumb,
    navigationButtom: navigationButtom,
    navigationTop: navigationTop,
    applicationLinks: [{ text: 'Apply now', class: 'bordred', link: '/header/applynow' }],
  }
  return (
    <>
      <Navigation
        navigationIntern={navigationIntern}
        {...headerData}
        updateLang={props.updateLang}
      />
      <FacilitiesBlock />
     
      <Footer {...footerEn} />
    </>
  )
}

export default FacilitiesServices
