import React from 'react'
import ContactUsBlock from '../components/ContactUsBlock'
import Navigation from '../components/Navigation'
import Footer from '../components/Footer'
import { footerEn /* footerAr*/ } from '../data/footer'
import { navigationTop, navigationButtom } from '../params'
import '../assets/scss/Funding.scss'

const navigationIntern = [
  // { text: 'Contact Us', hash: '#contact-us', isActive: true },
]

const crumb = [
  { link: '/', text: 'Home' },
  { link: '/research', text: 'Research' },
  { link: '/research/contact-us', text: 'Contact Us' },
]


function ResearchContactUs(props) {
  document.title = 'Research Contact Us - HBKU'
  const headerData = {
    title: 'Research at HBKU',
    crumb: crumb,
    navigationButtom: navigationButtom,
    navigationTop: navigationTop,
    applicationLinks: [{ text: 'Apply now', class: 'bordred', link: '/header/applynow' }],
  }
  return (
    <>
      <Navigation
        navigationIntern={navigationIntern}
        {...headerData}
        updateLang={props.updateLang}
      />
      <ContactUsBlock />

      <Footer {...footerEn} />
    </>
  )
}

export default ResearchContactUs
