import React, { useEffect, useState } from 'react';
import Footer from '../components/Footer';
import Navigation from '../components/Navigation';
import { footerEn } from '../data/footer';
import { navigationButtom, navigationTop } from '../params';

import '../assets/scss/Rms.scss';

const crumb = [
  { link: '/', text: 'Home' },
  { link: '/research', text: 'Research' },
  { link: '/research/rms', text: 'HBKU RMS' },
];

function Rms(props) {
  const [rmsData, setRmsData] = useState(null);

  useEffect(() => {
    document.title = 'Research Funding - HBKU';

    // Fetch data from the API
    fetch('https://www.hbku.edu.qa/en/api/hbku-pure.json')
      .then(response => response.json())
      .then(data => setRmsData(data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  if (!rmsData) {
    return <div>Loading...</div>;
  }

  // Assuming rmsData is an array and the first item contains the relevant data
  const { title, body, contact_us, pure_card_list } = rmsData[0];

  const headerData = {
    title: 'Research at HBKU',
    crumb: crumb,
    navigationButtom: navigationButtom,
    navigationTop: navigationTop,
    applicationLinks: [{ text: 'Apply now', class: 'bordred', link: '/header/applynow' }],
  };

  return (
    <>
      <Navigation
        navigationIntern={[]}
        {...headerData}
        updateLang={props.updateLang}
      />
 
      <section className="bg-grey-01-1 m-0 custom-research">
        <div className="container">
          <h2 className="text-hbku-primary p-b-2">{title}</h2>
          <div dangerouslySetInnerHTML={{ __html: body.safe_value }} />

          <div className="row py-0 py-lg-4 gx-4">
            {pure_card_list.map((card, index) => (
              <div key={index} className="col-md-6 col-lg-3">
                <figure className="list-pack-1">
                  <a href={card.pure_card_list_url}>
                    <img
                      src={card.pure_card_list_image}
                      alt={card.pure_card_list_title}
                      width="312"
                      height="342"
                      className="img-fluid"
                    />
                  </a>
                  <figcaption>
                    <h4 className="item--title">
                      <a href={card.pure_card_list_url}>{card.pure_card_list_title}</a>
                    </h4>
                  </figcaption>
                </figure>
              </div>
            ))}
          </div>

        
              <div dangerouslySetInnerHTML={{ __html: contact_us.safe_value }} />
      
        </div>
      </section>

      <Footer {...footerEn} />
    </>
  );
}

export default Rms;