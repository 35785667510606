import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navigation from '../components/Navigation';
import Loader from '../components/Loader'; 
import Footer from '../components/Footer';
import { footerEn } from '../data/footer';
import { navigationTop, navigationButtom } from '../params';
import { Link } from 'react-router-dom';

const navigationIntern = []

const crumb = [
  { link: '//', text: 'Home' },
  { link: '/', text: 'Research' },
  { link: '/research/core-labs', text: 'Core Labs' },
]

function CoreLabs(props) {
  const [data, setData] = useState(null);
  document.title = 'Research Core Labs'
  const poster = '/uploads/core-labs.png'
  //const src = '/uploads/welcome.mp4'
  const headerData = {
    title: 'Research at HBKU',
    crumb: crumb,
    navigationButtom: navigationButtom,
    navigationTop: navigationTop,
    applicationLinks: [{ text: 'Apply now', class: 'bordred', link: '/header/applynow' }],
  }

  useEffect(() => {
    axios.get('https://www.hbku.edu.qa/en/api/corelab.json/?tid=services')
      .then(response => {
        setData(response?.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);
  
  return (
    <>
      <Navigation
        navigationIntern={navigationIntern}
        {...headerData}
        updateLang={props.updateLang}
      />
      <section className="mt-5">
        <div className='container'>
          { data ?
          <>
            {data[0]?.services_description?.value && <div className='px-4' dangerouslySetInnerHTML={{ __html: data[0]?.services_description?.value}} />}
            <div className='row'>
                <div className="col-12">
                  <div className="t-block">
                      <div className="t-block__content ">
                        {Array.isArray(data) && data?.map(post => (
                          post?.display_lab === 'true' &&
                          <div key={post?.nid} className="t-block_list slow-up-down lab--cards">
                            <div className="t-block__list--image">
                              <figure className="ripple-effect overlay">
                                <img src={post?.core_tab_image}
                                  alt="" className="full-width" />
                              </figure>
                            </div>
                            <div className="t-block__list__info">
                              <div className="t-block__list--title">
                                <h4 className="item--title">{post?.title}
                                </h4>
                              </div>
                              <div className="t-block__list--content">
                                {post?.body}
                                <div className='py-3'> 
                                  <Link to={`/core-labs/${post?.corelab_type}/services`} className='l-btn read-more fw-bolder text-decoration-none' style={{color: '#0088CE'}}>
                                    Learn More <i className="ps-1 fas fa-arrow-right"></i>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        )) }
                      </div>
                    </div>
                </div>
            </div>
          </>
          : 
          <div className="d-flex justify-content-center">
              <Loader />
          </div>
          }
        </div>
      </section>
      <Footer {...footerEn} />
    </>
  )
}

export default CoreLabs;
