import { Component, createRef } from 'react'
import '../assets/scss/BannerVideoPlayer.scss'

function StartBtn(props) {
  if (!props.show) return <></>
  return (
    <div className='banner-video-overlay'>
      <div className="container full-height">
        <div className="banner-video-inner full-height">
      <div className='bannerTitle'>
          <h1> {props.BannerTitle} </h1>
          <div className='bannerDes'> <p> {props.BannerDes}</p> </div>
        </div>
        <div className="btnplay text-center ">
          <button
        className={props.btnTransparent ? 'btn-transparent ' : 'btn-transparent'}
        onClick={props.play}>
        <i className='fas fa-play '></i>
      </button>
        </div>
      
      </div>
      </div>
    </div>
  )
}

class BannerVideoPlayer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasControls: true,
    }
    this.video = createRef()
  }

  play = () => {
    this.setState({ hasControls: true })
    this.video.current.play()
  }

  render() {
    return (
      <div className={this.props.btnTransparent ? 'transparent banner-video-player' : 'banner-video-player'} style={{backgroundColor:'#001a41'}}>
        
         <video autoPlay muted loop controls={this.state.hasControls} ref={this.video} poster={this.props.poster} >
          <source src={this.props.src} />
        </video>
        
        <StartBtn
          btnTransparent={this.props.btnTransparent}
          show={!this.state.hasControls}
          play={this.play}
          BannerTitle={this.props.BannerTitle}
          BannerDes={this.props.BannerDes}
        />

      </div>
    )
  }
}

export default BannerVideoPlayer;
