import React, { Component } from 'react';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
        };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }

    componentDidCatch(error, errorInfo) {
        // Log the error or dispatch actions to handle the error globally
        console.error('Uncaught Error:', error);
        console.error('Error Info:', errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <main className="main mt-4" id="top">
                <div className="container" data-layout="container">
                    <div className="row flex-center justify-content-center min-vh-100 py-6 text-center">
                        <div className="col-sm-10 col-md-8 col-lg-6 col-xxl-5">
                            <div className="card mb-4">
                                <div className="card-body p-4 p-sm-5">
                                    <div className="fw-black lh-1 text-300 fs-error">500</div>
                                    <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">Whoops, something went wrong!</p>
                                    <p className="alert alert-danger">{this.state.error?.message}</p>
                                    <hr />
                                    <p>
                                        Try refreshing the page, or going back and attempting the action again.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
