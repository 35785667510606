import { Component } from 'react'
import { Link } from "react-router-dom";
import '../../assets/scss/FactsCaroussel.scss'

function CarousselItem(props) {
  let classN = 'fact-caroussel-item'
  if (props.first) classN += ' first'
  if (props.second) classN += ' second'
  if (props.third) classN += ' third'
  if (props.fourth) classN += ' fourth'
  if (props.old) classN += ' old'
  if (props.next) classN += ' next'
  return (
    <div className={classN}>
      {props.image == !props.image ? (
        <img src='https://www.hbku.edu.qa/sites/default/files/default_images/default.png' className='fact-caroussel-item-bg' />
       
      ) : (
        <img src={props.image} alt={props.name} className='fact-caroussel-item-bg' />
      )}
      <div className='fact-caroussel-overlay'>
        <div>
          <div className='fact-caroussel-date-day'>{props.day}</div>
          <div className='fact-caroussel-date-mounth'>{props.mounth}</div>
        </div>
        <div>
          <div className='fact-caroussel-tag'>
            Research  
            {/* {props.tag} */}
            </div>
          <div className='fact-caroussel-name'>
            <a href={props.url} target={'_blank'} rel="noreferrer" >{props.name}</a>
            </div>
        </div>
        <div className='fact-caroussel-description'>{props.description}</div>
        {/* <Link to={props.link}>{props.linkText}</Link> */}
      </div>
    </div>
  )
}

class Caroussel extends Component {
  constructor(props) {
    super(props)
    this.state = { show: 0 }
    this.timer = null
  }

  componentDidMount() {
    // this.timer = setInterval(this.next, 5000)
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  prev = () => {
    this.setState({
      show: this.state.show === 0 ? this.props.eventItems.length - 1 : this.state.show - 1,
    })
  }
  next = () => {
    this.setState({ show: (this.state.show + 1) % this.props.eventItems.length })
  }

  render() {
    const { show } = this.state
    const len = this.props.eventItems.length
    const old = (show + len - 1) % len
    const second = (show + 1) % len
    const third = (show + 2) % len
    const fourth = (show + 3) % len
    const next = (show + 4) % len
    return (
      <div className='fact-caroussel-container'>
        <button className='fact-caroussel-prev' onClick={this.prev}>
          <i
            className={
              this.props.lang === 'ar' ? 'fas fa-chevron-right' : 'fas fa-chevron-left'
            }></i>
        </button>
        <div className='fact-caroussel-items'>
          {this.props.eventItems.map((item, k) => (
            <CarousselItem
              key={k}
              {...item}
              k={k}
              old={old === k}
              first={show === k}
              second={second === k}
              third={third === k}
              fourth={fourth === k}
              next={next === k}
            />
          ))}
        </div>
        <button className='fact-caroussel-next' onClick={this.next}>
          <i
            className={
              this.props.lang === 'ar' ? 'fas fa-chevron-left' : 'fas fa-chevron-right'
            }></i>
        </button>
      </div>
    )
  }
}

// function Filter(props) {
//   if (props.noFilter) return <></>
//   const field = props.field
//   const fields = []
//   for (let i = 0; i < props.fields.length; i++) {
//     let e = props.fields[i]
//     if (!fields.includes(e[field])) fields.push(e[field])
//   }

//   return (
//     <div className='filter start sm no-marker'>
//       <button onClick={() => props.handler('')} className={props.selected === '' ? 'active' : ''}>
//         {props.lang === 'ar' ? 'الكل' : 'All'}
//       </button>
//       {fields.map((e, k) => (
//         <button
//           className={props.selected === e ? 'active' : ''}
//           onClick={() => props.handler(e)}
//           key={k}>
//           {e}
//         </button>
//       ))}
//     </div>
//   )
// }

// function SortBy(props) {
//   return (
//     <div className='sorter-container'>
//       <div className='sorter d-inline'>
//         {props.title}
//         <div className='sort-list'>
//           {['Date', 'Type', 'Name', 'Place'].map((e, k) => (
//             <button
//               name={e}
//               key={k}
//               onClick={props.handler}
//               className={props.selected === e ? 'active' : ''}>
//               {e}
//             </button>
//           ))}
//         </div>
//       </div>
//     </div>
//   )
// }

function FactsCaroussel(props) {
  //let [selected, setSelected] = useState('')
  //let [sortBy, setSortBy] = useState('')
  return (
    <section
      id={props.itemId}
      dir={props.lang === 'ar' ? 'rtl' : 'ltr'}
      className='facts-caroussel m-wrap'>
      <div className='container'>
        <div className='row'>
          <div className='col'>
            <h5 className="text-secondary">{props.subtitle}</h5>
            <h2>{props.title}</h2>
          </div>
          <div className='col text-end'>
            {/* <SortBy
              selected={sortBy}
              handler={event => setSortBy(event.target.name)}
              title='Filter upcoming event by '
            /> */}
          </div>
        </div>
        {/* <Filter
          lang={props.lang}
          noFilter={props.noFilter}
          handler={e => setSelected(e)}
          selected={selected}
          field='field'
          fields={[
            { field: 'Workshop' },
            { field: 'Webinar' },
            { field: 'Colloiquilm' },
            { field: 'Conference' },
            { field: 'Academic' },
          ]}
        /> */}
        <Caroussel lang={props.lang} eventItems={props.eventItems} />
        <div className='text-center'>
          <a href={`https://www.hbku.edu.qa/en/academic-calendar/2022-2023#/year/22692`} target='_blank' rel="noreferrer" className='action'>View More</a>
        </div>
      </div>
    </section>
  )
}

export default FactsCaroussel
