import React from 'react';
import { useHistory } from 'react-router-dom';

const ProteomicsServices = ({ subServices, subService }) => {
    const history = useHistory();

    const SubPages = ({page}) =>{
        if(page === 'olink'){
            return <div dangerouslySetInnerHTML={{ __html: subServices[0]?.body_1?.value }} />
        }
        else if (page === 'sengenics') {
          return <div dangerouslySetInnerHTML={{ __html: subServices[1]?.body_1?.value }} />
        } 
        else if (page === 'huport') {
          return <div dangerouslySetInnerHTML={{ __html: subServices[2]?.body_1?.value }} />
        } 
        else if (page === 'simoa') {
          return <div dangerouslySetInnerHTML={{ __html: subServices[3]?.body_1?.value }} />
        } 
        else{
          history.push('/core-labs/proteomics/services');
          return null;
        }
      }
    
    return (
        <SubPages page ={subService} />
    )
}

export default ProteomicsServices;