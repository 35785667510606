import React, { Component } from 'react'

import '../assets/scss/FacilitiesBlock.scss';
import '../assets/scss/NewsBlockList.scss';

class NewsBlockList extends Component{
  state = {

  };


  render(props){
    const { data } = this.props;
    const hbkuurl = 'https://www.hbku.edu.qa/en/node/'
    return (
              <div className="t-block_list newsblocklist--list slow-up-down">
                <a target='_blank' href={data.url}>
                    <div className="t-block__list--image">
                    <figure className="ripple-effect overlay">
                    <span className="hbku-tags custom-badge-cont m-b-0">
                    <span className={`custom-badge me-2`}>Research</span>
                    {/* {data.tag.map((e, k) => (
                        <span className={`custom-badge me-2 ${e.classN}`} key={k}>{e.text}</span>
                        ))} */}
                        
                    </span>
                    <img src={data.image}
                        alt="" className="full-width" />
                        {/* <span className="tags_bookmark save-tags">{data.savetag} <i className="far fa-bookmark "></i></span> */}
                    </figure>
                </div>
                <div className="t-block__list__info">
                    <div className="t-block__list--title">
                    <h4 className="item--title">{data.title}
                    </h4>
                    </div>
                    <div className="t-block__list--content">
                    {data.description}
                    </div>
                </div>
                </a>
            </div>
        )
  }
}

export default NewsBlockList