import '../../assets/scss/footer.scss';
import logoWhite from '../../assets/image/logo-white.svg'; 
import linked from '../../assets/image/ic-linked.svg'; 
import facebook from '../../assets/image/ic-facebook.svg'; 
import x from '../../assets/image/ic-x.svg'; 
import insta from '../../assets/image/ic-insta.svg'; 
import edx from '../../assets/image/ic-edx.svg'; 
// import mada from '../../assets/image/footer-mada.png'; 
// import FooterBottom from './FooterBottom'
// import FooterTop from './FooterTop'

function Footer(props) {
  // const direction = props.lang === 'ar' ? 'rtl' : 'ltr'
  return (
    // <footer dir={direction}>
    //   <FooterTop
    //     footerText={props.footerText}
    //     direction={direction}
    //     footermenues={props.footermenues}
    //   />
    //   <FooterBottom copyright={props.copyright} item={props.footerPages} direction={direction} />
    // </footer>
    <footer className="footer-main">
      <div className="container-lg border-bottom py-4 gx-0">
        <div className="row gy-4 gx-0 gx-lg-3 justify-content-center align-items-center py-lg-2 py-xxl-3">
          <div className="col-sm col-lg-3 footer-logo">
            <a href="https://www.hbku.edu.qa/en" target="_blank">
              <img src={logoWhite} alt="logo" />
            </a>
          </div>
          <div className="col-sm-auto col-lg footer-follow">
            <h5 className="h6 mb-3 text-white font-normal">Follow us</h5>
            <nav className="share-footer-holder">
              <a href="https://www.linkedin.com/edu/hamad-bin-khalifa-university-189106" target="_blank" className="share-icons">
                <img src={linked} alt="linkedin" />
                <span className="visually-hidden">Linkedin</span>
              </a>
              <a href="http://facebook.com/HamadbinKhalifaUniversity" target="_blank" className="share-icons">
                <img src={facebook} alt="fb" />
                <span className="visually-hidden">Facebook</span>
              </a>
              <a href="https://twitter.com/HBKU" target="_blank" className="share-icons">
                <img src={x} alt="x" />
                <span className="visually-hidden">X</span>
              </a>
              <a href="https://www.instagram.com/hbku/" target="_blank" className="share-icons">
                <img src={insta} alt="insta" />
                <span className="visually-hidden">Instagram</span>
              </a>
              <a href="#" target="_blank" className="share-icons">
                <img src={edx} alt="edx" />
                <span className="visually-hidden">Edx</span>
              </a>
            </nav>
          </div>
          <div className="col-sm-12 col-lg-5 col-lg-5">
            <h5 className="h6 mb-3 text-white font-normal">
              Subscribe to the official HBKU newsletter WAMDHA
            </h5>
            <div className="search-modal-field">
              <label htmlFor="search-input" className="col">
                <input
                  id="search-input"
                  type="text"
                  className="search-input w-100 px-2 px-xl-4"
                  placeholder="Your Email address"
                />
              </label>
              <button className="btn-search-in px-4" type="button">
                Subscribe
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container-lg border-bottom gx-0">
        <div className="row gx-4 pt-4 pt-md-5">
          <div className="col-lg-4 col-md mb-4">
            <h5 className="h5 font-normal mb-4 text-white-color">Colleges</h5>
            <div className="footer-nav">
              <ul>
                <li>
                  <a href="https://www.hbku.edu.qa/en/cis" target="_blank">College of Islamic Studies</a>
                </li>
                <li>
                  <a href="https://www.hbku.edu.qa/en/chss" target="_blank">College of Humanities and Social Sciences</a>
                </li>
                <li>
                  <a href="https://www.hbku.edu.qa/en/cse" target="_blank">College of Science and Engineering</a>
                </li>
                <li>
                  <a href="https://www.hbku.edu.qa/en/cl" target="_blank">College of Law</a>
                </li>
                <li>
                  <a href="https://www.hbku.edu.qa/en/chls" target="_blank">College of Health and Life Sciences</a>
                </li>
                <li>
                  <a href="https://www.hbku.edu.qa/en/cpp" target="_blank">College of Public Policy</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-auto col-md mb-4">
            <h5 className="h5 font-normal mb-4 text-white-color">Research Institutes and Centers</h5>
            <div className="footer-nav">
              <ul>
                <li>
                  <a href="https://www.hbku.edu.qa/en/qbri" target="_blank">Qatar Biomedical Research Institute (QBRI)</a>
                </li>
                <li>
                  <a href="https://www.hbku.edu.qa/en/qcri" target="_blank">Qatar Computing Research Institute (QCRI)</a>
                </li>
                <li>
                  <a href="https://www.hbku.edu.qa/en/qeeri" target="_blank">
                    Qatar Environment and Energy Research Institute (QEERI)
                  </a>
                </li>
                <li><a href="https://www.hbku.edu.qa/en/content/institutes-centers" target="_blank">View More</a></li>
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-md mb-4">
            <h5 className="h5 font-normal mb-4 text-white-color">Useful Links</h5>
            <div className="footer-nav">
              <ul>
                <li>
                  <a href="https://www.hbku.edu.qa/en/about-hamad-bin-khalifa-university" target="_blank">About HBKU</a>
                </li>
                <li>
                  <a href="https://www.hbku.edu.qa/en/academics" target="_blank">Academics</a>
                </li>
                <li>
                  <a href="https://www.hbku.edu.qa/en/admissions" target="_blank">Admission</a>
                </li>
                <li>
                  <a href="https://research.hbku.edu.qa/" target="_blank">Research</a>
                </li>
                <li>
                  <a href="https://www.hbku.edu.qa/en/careers" target="_blank">Careers</a>
                </li>
                {/* <li>
                  <a href="https://innovation.hbku.edu.qa/" target="_blank">Innovation</a>
                </li> */}
                <li>
                  <a href="https://www.hbku.edu.qa/en/life-hbku" target="_blank">Life at HBKU</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container-lg  py-4 gx-0">
        <div className="row gy-3 align-items-center gx-lg-5">
          <div className="col-12 col-lg order-3 order-md-1 pt-4 pt-lg-0">
            <p className="mb-0 text-footer-menu font-thin">
              {" "}
              © 2024 All rights reserved to Hamad Bin Khalifa University.
            </p>
          </div>
          <div className="col col-lg-auto order-1 order-md-2">
            <nav className="footer-nav d-flex column-gap-4 flex-column flex-sm-row">
              <a href="https://www.hbku.edu.qa/en/privacy-policy" target="_blank">Privacy Policy</a>
              <a href="https://www.hbku.edu.qa/en/legal-information" target="_blank">Cookies</a>
            </nav>
          </div>
          {/* <div className="col-auto col-lg-auto order-2 order-md-3">
            <a href="#" className="mada-logo">
              <img src={mada} alt="mada" />
            </a>
          </div> */}
        </div>
      </div>
    </footer>

  )
}
export default Footer;