import axios from 'axios'
import { useState } from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import { API_URL } from './params'
import FacilitiesServices from './views/FacilitiesServices'
import Funding from './views/Funding'
import HomePage from './views/HomePage'
import Impact from './views/Impact'
import News from './views/News'
import NewsDetails from './views/NewsDetails'
import NotFoundPage from './views/NotFoundPage'
import ResearchAtColleges from './views/ResearchAtColleges'
import ResearchContactUs from "./views/ResearchContactUs"
import ResearchHighlights from './views/ResearchHighlights'
import ResearchHighlightsDetail from './views/ResearchHighlightsDetail'
import InstituteView from './views/ResearchInstitutes'
import VPROBiograohy from './views/VPRBiography'
import VPROffice from './views/VPROffice'
import Compliance from './views/Compliance'
import ComplianceAtirb from './views/ComplianceAtirb'
import ComplianceAtibc from './views/ComplianceAtibc'
import CoreLabs from './views/CoreLabs'
import Rms from './views/Rms'
import ResearcherGuide from './views/ResearcherGuide'
import EditorGuide from './views/EditorGuide'
import DetailPage from './views/DetailPage'
import MasterCoreLabs from './views/core_labs'

function App() {
  axios.defaults.baseURL = API_URL

  const [lang, setLang] = useState('en')
  const updateLang = () => {
    if (lang === 'en') setLang('ar')
    else if (lang === 'ar') setLang('en')
  }
  
 

  return (
    <Router>
      <Switch>
        <Route path='/research/institutes' exact>
          <InstituteView lang={lang} updateLang={updateLang} />
        </Route>
        <Route path='/research/colleges' exact>
          <ResearchAtColleges lang={lang} updateLang={updateLang} />
        </Route>
        <Route path='/research/core-labs' exact>
          <CoreLabs lang={lang} updateLang={updateLang} />
        </Route>
        <Route path='/projects' exact>
          <ResearchHighlights lang={lang} updateLang={updateLang} />
        </Route>
        <Route path='/projects/:ResearchID'>
          <ResearchHighlightsDetail  lang={lang} updateLang={updateLang} />
        </Route>
        <Route path='/research/office-vpr' exact>
          <VPROffice lang={lang} updateLang={updateLang} />
        </Route>
        {/* <Route path='/research/office-vpr/biography' exact>
          <VPROBiograohy lang={lang} updateLang={updateLang} />
        </Route> */}
         <Route path='/research/funding'>
          <Funding lang={lang} updateLang={updateLang} />
        </Route>
        <Route path='/research/news-details'>
          <NewsDetails lang={lang} updateLang={updateLang} />
        </Route>
        <Route path='/research/hbku-rms'>
          <Rms lang={lang} updateLang={updateLang} />
        </Route>
        <Route path='/research/impact'>
          <Impact lang={lang} updateLang={updateLang} />
        </Route>
        <Route path='/research/facilities-services'>
          <FacilitiesServices lang={lang} updateLang={updateLang} />
        </Route>
        <Route exact path='/research/news' >
          <News lang={lang} updateLang={updateLang} />
        </Route>
        {/* <Route exact path='/research/events' >
          <EventsPage lang={lang} updateLang={updateLang} />
        </Route> */}
        <Route path='/research/office-vpr' exact>
          <VPROffice lang={lang} updateLang={updateLang} />
        </Route>
        <Route path='/research/compliance' exact>
          <Compliance lang={lang} updateLang={updateLang} />
        </Route>
        <Route path='/research/compliance/irb' exact>
          <ComplianceAtirb lang={lang} updateLang={updateLang} />
        </Route>
        <Route path='/research/compliance/ibc' exact>
          <ComplianceAtibc lang={lang} updateLang={updateLang} />
        </Route>
        <Route path='/research/contact-us'>
          <ResearchContactUs lang={lang} updateLang={updateLang} />
        </Route>
        <Route exact path='/research' >
          <HomePage lang={lang} updateLang={updateLang} />
        </Route>
        <Route exact path='/' >
          <VPROffice lang={lang} updateLang={updateLang} />
        </Route>
        <Route exact path="/research/editor_guide">
          <EditorGuide  lang={lang} updateLang={updateLang}  />
        </Route>
        <Route exact path="/research/researcher_guide">
          <ResearcherGuide  lang={lang} updateLang={updateLang} />
        </Route>
        <Route exact path="/research/:section_type/:id">
          <DetailPage  lang={lang} updateLang={updateLang} />
        </Route>
        {/* core labs routing start */}
        <Route exact path='/core-labs/:id/services'>
          <MasterCoreLabs page="services" lang={lang} updateLang={updateLang} />
        </Route>
        <Route exact path='/core-labs/:id/techniques'>
          <MasterCoreLabs page="techniques" lang={lang} updateLang={updateLang} />
        </Route>
        <Route exact path='/core-labs/:id/thin-film'>
          <MasterCoreLabs page="thin-film" lang={lang} updateLang={updateLang} />
        </Route>
        <Route exact path='/core-labs/:id/output'>
          <MasterCoreLabs page="output" lang={lang} updateLang={updateLang} />
        </Route>
        <Route path='/core-labs/:id/contact-us'>
          <MasterCoreLabs page="contact" lang={lang} updateLang={updateLang} />
        </Route>
        <Route path='/core-labs/:id/about-us'>
          <MasterCoreLabs page="about" lang={lang} updateLang={updateLang} />
        </Route>

        {/* proteomics services routing */}
        <Route path='/core-labs/:id/services/olink'>
          <MasterCoreLabs page="proteomicsServices" subService="olink" lang={lang} updateLang={updateLang} />
        </Route>
        <Route path='/core-labs/:id/services/sengenics'>
          <MasterCoreLabs page="proteomicsServices" subService="sengenics" lang={lang} updateLang={updateLang} />
        </Route>
        <Route path='/core-labs/:id/services/huport'>
          <MasterCoreLabs page="proteomicsServices" subService="huport" lang={lang} updateLang={updateLang} />
        </Route>
        <Route path='/core-labs/:id/services/simoa'>
          <MasterCoreLabs page="proteomicsServices" subService="simoa" lang={lang} updateLang={updateLang} />
        </Route>

        <Route path="*" component={NotFoundPage} /> 
     
      </Switch>
    </Router>
  )
}

export default App
