export const HeaderBanner = {
  title: 'Join Our Mailing List',
  placeholder: 'Your Email address here',
  subtitle: 'Stay up to date with our latest news and events',
}

export const HeaderBannerAr = {
  placeholder: 'عنوان بريدك الإلكتروني هنا',
  lang: 'ar',
  title: 'اشترك في النشرة الإخبارية لأحداثنا',
}
