import '../../assets/scss/Compliance.scss'

const CompliancePage = () => {
    return(
        <>
        <section className="m-wrap about-ovpr irb-ibc">
          <div className='container'>
            <h2 className="text-hbku-primary p-b-3">Research Compliance</h2>
            <p>The Research Compliance Assurance (RCA) section ensures that all research programs and employees at Hamad Bin Khalifa University (HBKU) comply with the rules and regulations set by the state of Qatar. The RCA requires that all institutional policies and procedures are being followed and that the performance of the institute meets the organization’s standards of conduct through the following committees:</p>
            <ul>
                <li>Institutional Review Board (IRB)</li>
                <li>Institutional Biosafety Committee (IBC)</li>
            </ul>
            <br /> 
            <h4 className="font-weight-bold">These committees ensure that certain requirements are maintained in all research activities. These requirements include: </h4>
            <div className="services-section">
                <div className="row row-eq-height">
                    <div className="services-category col-sm-6 col-md-4">
                        <div className="col-inner">
                                <div className="box-category">
                                    <div className="box-image">
                                        <img className="full-width" src="/uploads/scientific-merit.png" alt="" />
                                    </div>
                                    <div className="box-text">
                                        <h3 className="box-text--title">Scientific Merit</h3>
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div className="services-category col-sm-6 col-md-4">
                        <div className="col-inner">
                            <div className="box-category">
                                <div className="box-image">
                                    <img className="full-width" src="/uploads/privacy.png" alt="" />
                                </div>
                                <div className="box-text">
                                    <h3 className="box-text--title">Privacy</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="services-category col-sm-6 col-md-4">
                        <div className="col-inner">
                            <div className="box-category">
                                <div className="box-image">
                                    <img className="full-width" src="/uploads/respect-people.png" alt="" />
                                </div>
                                <div className="box-text">
                                    <h3 className="box-text--title">Respect for people</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="services-category col-sm-6 col-md-4">
                        <div className="col-inner">
                            <div className="box-category">
                                <div className="box-image">
                                    <img className="full-width" src="/uploads/collaborative-partnerships.png" alt="" />
                                </div>
                                <div className="box-text">
                                    <h3 className="box-text--title">Collaborative Partnerships</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="services-category col-sm-6 col-md-4">
                        <div className="col-inner">
                            <div className="box-category">
                                <div className="box-image">
                                    <img className="full-width" src="/uploads/biosafety.png" alt="" />
                                </div>
                                <div className="box-text">
                                    <h3 className="box-text--title">Biosafety</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="services-category col-sm-6 col-md-4">
                        <div className="col-inner">
                            <div className="box-category">
                                <div className="box-image">
                                    <img className="full-width" src="/uploads/risk.png" alt="" />
                                </div>
                                <div className="box-text">
                                    <h3 className="box-text--title">Risk assessment</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </section>
        </>
    )
}

export default CompliancePage;