import Footer from '../components/Footer'
// import { Link } from 'react-router-dom'
import Navigation from '../components/Navigation'
// import WelecomeQuoteInline from '../components/Quotes/WelecomeQuoteInline'
import { footerEn } from '../data/footer'
import { navigationButtom, navigationTop } from '../params'

import '../assets/scss/VprOffice.scss'

const navigationIntern = [
  { text: 'About OVPR', hash: '/research/office-vpr#about-ovpr' },
  // { text: 'Welcome message', hash: '/research/office-vpr#vpr-office' },
  // { text: 'VPR Bio', hash: '/research/office-vpr/biography#vpr-office-bio', icon:'fas fa-external-link-alt', isActive: true},
]

const crumb = [
  { link: '//', text: 'Home' },
  { link: '/', text: 'Research' },
  { link: '/research/about', text: 'VPR Office' },
]


function VPROBiograohy(props) {
  document.title = 'VPR Office - HBKU'
  const headerData = {
    title: 'Research at HBKU',
    crumb: crumb,
    navigationButtom: navigationButtom,
    navigationTop: navigationTop,
    applicationLinks: [{ text: 'Apply now', class: 'bordred', link: '/header/applynow' }],
  }
  return (
    <>
      <Navigation
        navigationIntern={navigationIntern}
        {...headerData}
        updateLang={props.updateLang}
      />
     <section id='vpr-office-bio' className="m-wrap">
        <div className='container'>
          <div className='row'>
            <div className="col-sm-12">
              <h2 className='text-hbku-primary m-in-section'>VPR Biography </h2>
               <div className="mez person person-newlayout person--flip p-b-0 m-b-0">
								<div className="l-article">
									<figure className="p-r-2 p-b-1 width460">
										<img src='/uploads/richard-bio-lg.jpg' alt='VPR Office' className="bordred-secondary img-responsive img-circle6" width="460" />
									</figure>
                  <p>Dr. Richard O’Kennedy, BSc, PhD, C. Biol., FRSB., F.I.Biol.I., Dip. F.S., Dip. C.S., Dip. Computing, MRIA, is the Vice-President for Research at Hamad Bin Khalifa University (HBKU), and the Vice-President for Research, Development and Innovation (RDI) at Qatar Foundation, Doha, Qatar. He is a member of the Royal Irish Academy and the Qatar RDI Council.</p>
                  <p>Previously, he was a Professor and the Scientific Director of the Biomedical Diagnostics Institute at Dublin City University (DCU) Ireland. He is the past President of the London International Youth Science Forum, Past President of the Institute of Biology of Ireland, past Chairman/Founder of the Centre for Talented Youth (CTY) in Ireland, and has represented Ireland and the Royal Irish Academy (RIA) at the International Union of Biochemistry and Molecular Biology (IUBMB). In 2018, he was selected as the representative of the IUBMB for Qatar. He was a member of the Industrial Research and Commercialisation Committee of Enterprise Ireland, the National Bioethics Council for Ireland, and the Life Sciences and Health Committee of the RIA.</p>
                  <p>Prior to this, he was the Vice-President for Teaching and Learning at DCU, Chair of the Management Committee of the Dublin Region Higher Education Alliance (11 Institutions and ca 66,000 students) and Visiting Scientist at M.D. Anderson Cancer Centre at the University of Texas at Houston, where his work was selected as ‘Invention of the Year’.</p>
                  <p>He directs the Applied Biochemistry Research Group that is internationally recognized for its expertise in antibody generation and immuno/ biosensor assay development. He has published extensively (250 peer-reviewed papers, 51 reviews, 40 book chapters, two books + one  in process), has mentored 70 PhDs to completion, and has a Google Scholar H-Index of 54 with over 12,000 citations.</p>
                  <p>Dr. O’Kennedy has academic collaborations worldwide and has carried out many collaborations with industry, has seven patents, multiple licenses, and many of his reagents/innovations have been licensed and commercialized.</p>
                  <p>He is the Founder and Chief Scientific Officer of AbYBiotech, Chairman of the Scientific Board of Glycoselect – a highly innovative Biotech company, has acted as a reviewer for over 60 journals, is the Editor of three journals, and acts as a reviewer for ca 30 international funding bodies. </p>
                  <p>Dr. O’Kennedy has a keen interest in innovative approaches to healthcare, and has received many awards for Research: Biochemistry Medal of the RIA; President’s Award for Research, three Fujitsu Awards for Innovation; Ireland’s Bioscience Lab of the Year, 2014, Teaching: President’s award, DCU; Outstanding Masters Programme Award, Graduate Ireland, 2011, and Outreach: Outstanding Education and Outreach Lab, 2017; CTY award, 2018.</p>
								</div>
							</div>
            </div>
          </div>
        </div>
      </section>

      <section id='vpr-office-bio' className="m-wrap" style={{backgroundImage:'url(/uploads/vpr-contact-bg.png)'}}>
        <div className='container'>
          <div className='row'>
            <div className="col-sm-12">
              <h2 className='text-hbku-primary p-b-2'>Contact: </h2>
              {/* <h6>Email: <a href="mailto:rkennedy@hbku.edu.qa"> rkennedy@hbku.edu.qa</a></h6> */}
              <p><strong>Office Address:</strong> <br />Hamad Bin Khalifa University <br />Research Complex, Education City, <br />Doha, Qatar</p>
              <div className="text-left"><a className="action m-t-3 m-b-1" href="mailto:rkennedy@hbku.edu.qa">Contact the OVPR </a></div>
            </div>
          </div>
        </div>
      </section>    
      <Footer {...footerEn} />
    </>
  )
}

export default VPROBiograohy
