import { Component } from 'react'
import '../../assets/scss/Testimonials.scss'
const interval = 10000

class Testimonials extends Component {
  constructor(props) {
    super(props)
    this.state = { selected: 0 }
    this.timer = null
  }

  change = n => {
    this.setState({ selected: n })
    clearInterval(this.timer)
    this.timer = setInterval(this.next, interval)
  }

  componentDidMount() {
    this.timer = setInterval(this.next, interval)
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  next = () => {
    let { selected } = this.state
    selected = (selected + 1) % this.props.items.length
    this.setState({ selected })
  }

  render() {
    const { lang } = this.props
    const { selected } = this.state

    return (
      <section dir={lang === 'ar' ? 'rtl' : 'ltr'} className='testimonials'>
        <div className='container'>
          <h2>{this.props.title}</h2>
          <div className='testimonials-container'>
            <img
              src={this.props.items[selected].image}
              className='testimonials-image'
              alt={this.props.items[selected].writer.name}
            />
            <div className='testimonials-quote'>
              <q dir={lang === 'ar' ? 'rtl' : 'ltr'}>{this.props.items[selected].quote}</q>
              <div className='testimonials-writer'>
                <div className='testimonials-writer-name'>
                  {this.props.items[selected].writer.name}
                </div>
                <div className='testimonials-writer-position'>
                  {this.props.items[selected].writer.position}
                </div>
              </div>
              <div className='testimonials-selector'>
                {this.props.items.map((e, k) => (
                  <img
                    className={`testimonials-selector-item${selected === k ? ' active' : ''}`}
                    src={e.writer.avatar}
                    onClick={() => this.change(k)}
                    alt={e.writer.name}
                    key={k}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
export default Testimonials
