import React, { Component } from 'react'

import FacilitiesBlockList from "./FacilitiesBlockList";

class FacilitiesBlock extends Component{
   state = {
     facilitiesContent : [
      {
        hbkutag: 'QBRI',
        savetag: 'Save',
        avatar:'/uploads/facilities/advenced imaging.png',
        title: 'Advanced Imaging and Histopathology Core',
        description: ' QBRI’s Advanced Imaging and Histopathology Core provides a wide range of Optical Microscopy, Digital Imaging and Histopathology services that support investigators in their research endeavors.',
        showhideText:'With a variety of microscopes and imaging systems, the Core offers researchers efficient, reliable and innovative imaging solutions with the highest standard instrumentation, accuracy, quality control, and professional expertise. For more information, visit:',
        btntext: 'Read More',
        id:'1'
      },
      {
        hbkutag: 'QBRI',
        savetag: 'Save',
        avatar:'/uploads/facilities/flow-cytometry-core.png',
        title: 'Flow Cytometry Core ',
        description: 'QBRI’s Flow Cytometry Core offers investigators innovative and high-quality flow cytometry and cell sorting solutions with its state-of-the-art instrumentation and scientific expertise.',
        showhideText:'The core serves to optimize the quality of scientific research conducted at HBKU. For more information, visit:',
        btntext: 'Read More',
        id:'2'
      },
      // {
      //   hbkutag: 'QBRI',
      //   savetag: 'Save',
      //   avatar:'/uploads/facilities/genomic-core.png',
      //   title: 'Genomics Core and Genome Technology',
      //   description: 'QBRI’s Genomics Core boasts various cutting-edge, high-throughput technological platforms for genomic analyses. This facility strives to provide a broad range of state-of-the-art genomics services to scientists and support them in generating high quality research data.',
      //   showhideText:'Its applications include sequencing of different nucleic acid templates, high throughput genotyping, epigenome analysis, gene expression analysis, single-cell technologies, NanoString technologies and digital PCR methods. For more information, visit:',
      //   btntext: 'Read More',
      //   id:'3'
      // },
      {
        hbkutag: 'QBRI',
        savetag: 'Save',
        avatar:'/uploads/facilities/stem-cell-core.png',
        title: 'Stem Cell Core',
        description: 'QBRI’s Stem Cell core enables investigators to conduct stem cell research by providing scientific advice, iPSCs derivation, directed differentiations and genome engineering services.',
        showhideText:'The facility is fully equipped with standard and state-of-the-art cell culture, cell biology and molecular biology equipment. For more information, visit:',
        btntext: 'Read More',
        id:'4'
      },
      // {
      //   hbkutag: 'QBRI',
      //   savetag: 'Save',
      //   avatar:'/uploads/facilities/protemoic-core.png',
      //   title: 'Proteomics Core',
      //   description: 'QBRI’s Proteomics Core operates a cutting-edge technological platform, offering users access to high throughput, affinity-based proteomics assays to scientists tackling protein-based challenges.',
      //   showhideText:'The  platforms include Olink, Sengenics, MesoScale Discovery (MSD), Luminex Bio-Plex 3D and Simoa. Besides pure analytical capabilities, the core offers additional services, including technological consultation, study design, custom panel selection, experimental design optimization, as well as biostatistical analysis. For more information, visit:',
      //   btntext: 'Read More',
      //   id:'5'
      // },
      {
        hbkutag: 'QEERI',
        savetag: 'Save',
        avatar:'/uploads/facilities/outdoor-test-facility.jpg',
        title: 'Outdoor Test Facility',
        description: ' QEERI’s Outdoor Test Facility, located at the Qatar Science and Technology Park (QSTP), enables researchers to identify the most suitable technologies for Qatar’s climate, and their optimal configuration and operation methods.',
        showhideText:'The facility tests a wide variety of technologies under Qatar’s harsh environmental conditions, to further analyze, develop, and test solar and other technologies that can be used in Qatar and across the region.',
        btntext: 'Read More',
        id:'6'
      },
      // {
      //   hbkutag: 'QEERI',
      //   savetag: 'Save',
      //   avatar:'/uploads/facilities/micro-nanofabrication.png',
      //   title: 'Core Labs',
      //   description: 'QEERI’s Core Labs enable fundamental and applied cutting-edge research in Qatar. With highly skilled staff and distinctive capabilities, the Core Labs provide a centralized, world-class facility housing state-of-the-art equipment.',
      //   showhideText:' The facility supports researchers with its analytical and materials characterization tools and expertise.',
      //   btntext: 'Read More',
      //   id:'7'
      // },
      {
        hbkutag: 'UTS',
        savetag: 'Save',
        avatar:'/uploads/facilities/data-center.png',
        title: 'Data Center',
        description: 'HBKU’s Data Center was designed and built to provide a state-of-the-art data center for research and education Information Technology needs.',
        showhideText:'It is populated with 71 racks and consists of six zones, which are based on hot aisle containment, and a seventh zone that is allocated for high performance and high-density computing that  which is water-cooled. All of these technologies are fully integrated into an intelligent data center management platform providing 360-degree visibility, advanced monitoring, alerts, and capacity planning.',
        btntext: 'Read More',
        id:'8'
      },
      {
        hbkutag: 'CHLS',
        savetag: 'Save',
        avatar:'/uploads/facilities/molecular-imaging.png',
        title: 'Molecular Imaging and Microscopy Facility',
        description: 'The Molecular Imaging and Microscopy Facility is equipped with high-resolution instruments including spinning disk confocal as well as TIRF microscopy. ',
        showhideText:'The facility provides essential means to visualize molecules in the different cellular compartments and their interactions.',
        btntext: 'Read More',
        id:'9'
      },
      {
        hbkutag: 'CHLS',
        savetag: 'Save',
        avatar:'/uploads/facilities/high-throughput-drug.png',
        title: 'High Throughput Drug Screening Facility',
        description: 'The High Throughput Drug Screening Facility is a robotic workstation and high content screening system, which provides the necessary',
        showhideText:'tools for systematic screening of drug efficiency and toxicity on living cells.',
        btntext: 'Read More',
        id:'10'
      },
      {
        hbkutag: 'CHLS',
        savetag: 'Save',
        avatar:'/uploads/facilities/cell-molecular-biology_.png',
        title: 'Cell and Molecular Biology Facility',
        description: 'From basic nucleic acid extractions to DNA sequencing, and from basic cellular culturing to protein expression, the Cell and Molecular Biology facility houses minor and major equipment,',
        showhideText:'enabling researchers to carry on all related experiments to understand and uncover mechanisms pertaining to cellular differentiation, proliferation, apoptosis, senescence, and autophagy. Yeast and bacterial genetic screening as well as CRISPR-Cas9 gene editing is also carried out in this facility.',
        btntext: 'Read More',
        id:'11'
      },
       {
        hbkutag: 'CHLS',
        savetag: 'Save',
        avatar:'/uploads/facilities/small-microscopic-animal.png',
        title: 'Small Microscopic-Animals Facility',
        description: 'The CHLS laboratory space includes two dedicated areas equipped with the necessary equipment to ensure the growth and maintenance of Drosophila and C. Elegans genetic strains,',
        showhideText:' providing the best setting to uncover in vivo mechanisms implicated in development and disease.',
        btntext: 'Read More',
        id:'12'
      },
      {
        hbkutag: 'CHLS',
        savetag: 'Save',
        avatar:'/uploads/facilities/bioinformatics-facility_.png',
        title: 'Bioinformatics Facility',
        description: 'At this facility, all computational analyses of biological data are performed on dedicated workstations with different software allowing optimal interpretations of genomics,',
        showhideText:'transcriptomics, epigenetics, and pharmacogenomics data.',
        btntext: 'Read More',
        id:'13'
      },
      {
        hbkutag: 'CHSS',
        savetag: 'Save',
        avatar:'/uploads/facilities/translation-Interpreting.png',
        title: 'The Translation and Interpreting Institute (TII)',
        description: 'The Translation and Interpreting Institute (TII), part of CHSS, provides advanced translator and interpreter education, high-quality training in a range of languages, and translation and interpreting services of the highest international standards.',
        showhideText:' Led by a distinguished international team of faculty and staff, TII consists of a Postgraduate Studies and Research Department, a Language Center, and a Professional Services Center. For more information, visit: <a href="https://www.hbku.edu.qa/en/chss/center/translation-interpreting-institute" target="_blank">https://www.hbku.edu.qa/en/chss/center/translation-interpreting-institute </a> ',
        btntext: 'Read More',
        id:'14'
      },
      {
        hbkutag: 'CL',
        savetag: 'Save',
        avatar:'/uploads/facilities/minaretein-library.png',
        title: 'Minaretein Library',
        description: 'The College of Law is located in the Minaretein Building, which makes it convenient for our faculty and students to access research material and books at the Islamic Studies Library.',
        showhideText:'The Library has special collections for law-related research.',
        btntext: 'Read More',
        id:'15'
      },
      {
        hbkutag: 'CL',
        savetag: 'Save',
        avatar:'/uploads/facilities/workshop-conference.png',
        title: 'Workshop and Conference Facilities',
        description: 'The Minaretein Building offers world-class facilities for research workshops and conferences.',
        showhideText:'The College of Law frequently organizes workshops and conferences where faculty members and students introduce and present their ongoing research to their peers, as well as showcase their publications to a global audience.',
        btntext: 'Read More',
        id:'16'
      },
      //  {
      //   hbkutag: 'CHSS',
      //   savetag: 'Save',
      //   avatar:'/uploads/facilities/aeare.png',
      //   title: 'Academic Writing and Research Excellence (AWARE) Centre',
      //   description: 'CHSS’ AWARE center supports students with developing their academic writing and research skills in both English and Arabic. The center aims to assist students in becoming better-skilled writers in due course.',
      //   showhideText:'In support of its goal, the center provides individual appointments, workshops, and digital resources. For more information, visit: <a href="https://www.hbku.edu.qa/en/chss/aware" target="_blank"> https://www.hbku.edu.qa/en/chss/aware</a>',
      //   btntext: 'Read More',
      //   id:'17'
      // },
      {
        hbkutag: 'QCRI',
        savetag: 'Save',
        avatar:'/uploads/facilities/creative-space.png',
        title: 'Creative Space',
        description: 'The Creative Space is QCRI’s education center that provides K-12 students with an inspiring learning environment to foster creativity, innovation, and teamwork.',
        showhideText:'It empowers the students to explore and develop their skills in the fields of computer science and engineering by implementing creative projects. For more information, visit: <a href="https://creativespace.qcri.org/" target="_blank">https://creativespace.qcri.org/ </a> ',
        btntext: 'Read More',
        id:'18'
      },
      // {
      //   hbkutag: 'CIS',
      //   savetag: 'Save',
      //   avatar:'/uploads/facilities/qcimr.png',
      //   title: 'Al-Qaradawi Center For Islamic Moderation and Renewal (QCIMR)',
      //   description: 'CIS’ Al-Qaradawi Center for Islamic Moderation and Renewal (QCIMR) focuses on fostering Islamic moderation through scientific research. By addressing various issues, the center’s vision encompasses a society based on moderation and cooperative openness and enlightened justice.',
      //   showhideText:' The center’s objectives include training scholars in the field of Islamic studies, providing consultation on religious and cultural issues, and creating a database of information related to Islamic moderation. For more information, visit: <a href="https://www.hbku.edu.qa/en/cis/center/qcimr" target="_blank">https://www.hbku.edu.qa/en/cis/center/qcimr </a> ',
      //   btntext: 'Read More',
      //   id:'19'
      // },
      {
        hbkutag: 'CIS',
        savetag: 'Save',
        avatar:'/uploads/facilities/cmcc.png',
        title: 'Muhammad Bin Hamad AlThani Center For Muslim Contribution to  Civilization (CMCC)',
        description: 'CIS’ Muhammad Bin Hamad Al-Thani Center for Muslim Contribution to Civilization (CMCC) aims to translate a selection of the important scholarly works of Islamic Civilization into English and other major languages. ',
        showhideText:'The center currently provides English translations of major works of Muslim scholars when Islamic civilization was at its peak, shaping the contemporary world civilizations and laying the foundations for the European Renaissance. For more information, visit: <a href="https://www.hbku.edu.qa/en/cis/center/cmcc" target="_blank"> https://www.hbku.edu.qa/en/cis/center/cmcc</a> ',
        btntext: 'Read More',
        id:'20'
      },
      {
        hbkutag: 'CIS',
        savetag: 'Save',
        avatar:'/uploads/facilities/cief.png',
        title: 'Center for Islamic Economics  and Finance (CIEF)',
        description: 'CIS’Center for Islamic Economics and Finance is a center of excellence in the scientific discipline of Islamic economics and finance.',
        showhideText:'It aims  to endorse economic justice and prosperity through the development of genuine financial products, monetary and fiscal policies, and business and trade practices. The goal is for its research to provide contextual maps for ethically grounded economies. For more information, visit: <a href="https://www.hbku.edu.qa/en/cis/center/cief" target="_blank">https://www.hbku.edu.qa/en/cis/center/cief </a> ',
        btntext: 'Read More',
        id:'21'
      },
      // {
      //   hbkutag: '',
      //   savetag: 'Save',
      //   avatar:'/uploads/facilities/default.png',
      //   title: 'Molecular-BeamEpitaxy (MBE) Technology',
      //   description: 'Molecular-beam Epitaxy (MBE) is an epitaxial process by which growth of materials takes place under ultra high vacuum (10-7 and 10-12 mbar) conditions on a heated crystalline substrate by the interaction of adsorbed species supplied by atomic or molecular beams.',
      //   showhideText:'The individual components of the crystals are supplied to the heated substrate by evaporation or sublimation of suitable materials contained in ultra-pure crucibles. The most important aspect of the technique is the slow crystal growth rate compared to chemical vapor deposition and liquid phase epitaxy. This lower growth rate combined with the Reflection High Energy Electron Diffraction in-situ characterization technique makes it possible to obtain a significantly better control of composition and thickness at an atomic level. The first MBE systems were developed for the growth of semiconducting compounds, and later the technique was exploited for the deposition of metals and insulators. MBE is considered one of the fundamental tools for the development of nanotechnologies. This technology allows the growth of a wide range of materials, including Si, Ge, III–V semiconductors, II–VI semiconductors,and metals and oxides, which are utilized nowadays to manufacture many sophisticated devices used in our daily lives  such as detectors, lasers, LEDs, transistors, high-efficiency solar cells and micro-electromechanical systems (MEMS).',
      //   btntext: 'Read More',
      //   id:'22'
      // },
      // {
      //   hbkutag: '',
      //   savetag: 'Save',
      //   avatar:'/uploads/facilities/default.png',
      //   title: 'Advanced MED Pilot plant',
      //   description: 'Production of freshwater from seawater is critical in Qatar. In order to reduce the energy consumption and to minimize the environmental impact of seawater desalination and brine treatment,',
      //   showhideText:'QEERI has designed and installed an advanced Multi-Effect Distillation (MED) pilot plant based on in–house design with significant improvements to the traditional process. With a nominal capacity of 25,000 liters/day, and operating under the high seawater salinity of the west coast of Qatar, the pilot plant energy requirements are 40% less for the heating steam consumption, and 55% lower for the pumping power, therefore the specific power consumption of a full-scale commercial plant based on the novel design would be 4.6 kWh/m3. QEERI is further improving the process by evaluating antiscalants and also by developing the integration of thermal enhanced polymer plates/tubes instead of metal tubes. The institute has signed a 20-year agreement with Qatar Electricity and Water Company (QEWC), with the aim of reducing the cost of producing fresh water.',
      //   btntext: 'Read More',
      //   id:'23'
      // },
      //  {
      //   hbkutag: '',
      //   savetag: 'Save',
      //   avatar:'/uploads/facilities/default.png',
      //   title: 'Ablution/AC Condensate Water Recycling',
      //   description: 'The Ablution/AC Condensate Water Recycling rig is a customer-designed equipment with the purpose of treating ablution and air conditioning condensate water.',
      //   showhideText:'The equipment consists of multiple unit processes including filtration, adsorption, UV, and chlorine disinfection.',
      //   btntext: 'Read More',
      //   id:'24'
      // },
      // {
      //   hbkutag: '',
      //   savetag: 'Save',
      //   avatar:'/uploads/facilities/default.png',
      //   title: 'Air Gap Membrane Distillation Unit (AGMD)',
      //   description: 'The Air Gap Membrane Distillation Unit (AGMD) is a lab-scale unit that can be utilized in order to investigate the efficiency and feasibility of freshwater reclamation from seawater, brine, wastewater, and more.',
      //   showhideText:'An air gap separates the hydrophobic membrane from a cool condensing surface, thus providing the main advantage of AGMD against the other MD configurations. AGMD introduces an air-filled cavity between the membrane and the condensation surface in which case the vapor must penetrate through the membrane thickness and additionally, across the air gap before it reaches the cold surface. This stagnant air gap acts as a thermal insulation layer between the hot feed and the cooling surface, resulting in a significant reduction in heat loss by conduction through the membrane which makes AGMD the most energy-efficient MD configuration.',
      //   btntext: 'Read More',
      //   id:'25'
      // },
      // {
      //   hbkutag: '',
      //   savetag: 'Save',
      //   avatar:'/uploads/facilities/default.png',
      //   title: 'Dissolved Air Flotation (DAF) System',
      //   description: 'The Dissolved Air Flotation System (DAF) is a water treatment process that clarifies wastewaters (or other waters) by removing suspended matter such as oils or solids.',
      //   showhideText:'The unit works by attaching small bubbles of air to the suspended solids. The bubbles are generated by saturating a recycled stream of water with air under pressure, then releasing the pressure rapidly to produce clouds of microbubbles. Flotation is a mechanical process used to remove undissolved organic and inorganic substances from water. It is mainly used for substances whose density is almost the same as, or lower than that of water. These substances would sediment very slowly and would remain suspended within the body of water being investigated.',
      //   btntext: 'Read More',
      //   id:'26'
      // },
      //  {
      //   hbkutag: '',
      //   savetag: 'Save',
      //   avatar:'/uploads/facilities/default.png',
      //   title: 'Ion Exchange (IX) Pilot Plant',
      //   description: 'Ion exchange (IX) is a pilot plant for removing metals and other inorganic compounds from water. Arsenic, cadmium, chromium, copper, lead, nickel, selenium, radionuclides, and zinc are just a few examples of the compounds that the  Ion Exchange pilot plant can remove from wastewater.',
      //   showhideText:' The Ion Exchange pilot plant can also be used to remove non-metallic compounds such as ammonia, nitrate, perchlorate, and silicate.',
      //   btntext: 'Read More',
      //   id:'27'
      // },
      // {
      //   hbkutag: '',
      //   savetag: 'Save',
      //   avatar:'/uploads/facilities/default.png',
      //   title: 'Membrane Bioreactor (MBR)',
      //   description: 'The Membrane Bioreactor (MBR) is the combination of a membrane process like microfiltration or ultrafiltration with a biological wastewater treatment process, and the activated sludge process.',
      //   showhideText:'MBR can be broadly used for municipal and industrial wastewater treatment. Two MBR configurations exist: internal/submerged, where the membranes are immersed and integral to the biological reactor; and external/side stream, where membranes are a separate unit process requiring an intermediate pumping step.',
      //   btntext: 'Read More',
      //   id:'28'
      // },
      // {
      //   hbkutag: '',
      //   savetag: 'Save',
      //   avatar:'/uploads/facilities/default.png',
      //   title: 'Treated Sewage Effluent (TSE) Pilot Plant',
      //   description: 'The Treated Sewage Effluent (TSE) Pilot Plant is made up of three different Advanced Oxidation technologies namely (i) Ozone (ii) Ozone-Hydrogen peroxide and Ultraviolet-Hydrogen peroxide. ',
      //   showhideText:'They are used to process and remove target micro-organic pollutants from the Treated Sewage Effluent at a wastewater treatment plant. The pilot plant is designed to evaluate the performance of different trains of advanced oxidation processes. The pilot plant offers experimental control by allowing adjustment of the flow parameters and dosing of chemicals and reagents. It also has the functionality of controlling, operating and downloading data remotely. This system has been specifically designed in-house by QEERI scientists.',
      //   btntext: 'Read More',
      //   id:'29'
      // },
    ]
   }
    
  render(){
    const { facilitiesContent } = this.state;
    return (
      <section id='FacilitiesBlock' className="m-wrap">
        <div className='container'>
          <div className='row'>
            <div className="col-12">
              <div className="t-block">
									<div className="t-block__content ">
                    {facilitiesContent.map(data => (
                      <FacilitiesBlockList
                        key={data.id}
                        data={data}
                      />
                    ))}

									</div>
								</div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}


export default FacilitiesBlock