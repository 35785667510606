import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useHistory } from 'react-router-dom';
import Navigation from '../../components/Navigation';
import Loader from '../../components/Loader'; 
import Footer from '../../components/Footer';
import { footerEn /* footerAr*/ } from '../../data/footer';
import { navigationTop, materialLab, proteomicsLab, genomicsLab } from '../../params';
import Services from './Services';
import AboutUs from './AboutUs';
import ContactUs from './ContactUs';
import Techniques from './Techniques';
import Output from './Output';
import ErrorBoundary from '../../components/ErrorBoundary';
import ProteomicsServices from './ProteomicsServices';


const MasterCoreLabs = ({ page, updateLang, subService }) => {
   
    const [services, setServices] = useState(null);
    const [subServices, setSubServices] = useState(null);
    const [techniques, setTechniques] = useState(null);
    const [output, setOutput] = useState(null);
    const [pastEvents, setPastEvents] = useState(null);
    const [about, setAbout] = useState(null);
    const [contact, setContact] = useState(null);
    const [dynamicRoutes, setDynamicRoutes] = useState(null);
    const { id } = useParams();
    const history = useHistory();

    const headerData = {
      title: services ? services[0]?.title :'...',
      crumb: subService === undefined ? [
        { link: '/research/core-labs', text: 'Core Labs' },
        { link: '/research/core-labs', text: services ? services[0]?.title : '...' },
      ] : [
        { link: '/research/core-labs', text: 'Core Labs' },
        { link: '/core-labs/proteomics/services', text: 'Proteomics Core Lab' },
        { link: '/core-labs/proteomics/services', text: 'Services' },
      ],
      navigationCoreLabs: dynamicRoutes,
      navigationTop: navigationTop,
      applicationLinks: [
        { text: 'Apply now', class: 'bordred', link: 'https://hbkuqa.elluciancrmrecruit.com/Apply/Account/Login?ReturnUrl=%2fApply' },
      ],
    };
    useEffect(() => {
        axios.get(`https://www.hbku.edu.qa/en/api/corelab.json?lab=${id}`)
          .then(response => {
            const techniqueArray = response?.data?.filter(item => item.short_page_type_name === 'techniques');
            setTechniques(techniqueArray);
            const outputArray = response?.data?.filter(item => item.short_page_type_name === 'output');
            setOutput(outputArray);
            setPastEvents(response?.data?.filter(item => item.short_page_type_name === 'past_events'));
            setServices(response?.data?.filter(item => item.short_page_type_name === 'services'));
            setSubServices(response?.data?.filter(item => item.short_page_type_name === 'pro_sub_services'));
            setAbout(response?.data?.filter(item => item.short_page_type_name === 'about_us'));
            setContact(response?.data?.filter(item => item.short_page_type_name === 'contact_us'));
          })
          .catch(error => {
            console.error('Error fetching Services details:', error);
          });
          window.scrollTo(0, 0);

          if(id === 'material') {
            setDynamicRoutes(materialLab) 
            document.title = 'Core Labs - Materials'};
          if(id === 'proteomics') {
            setDynamicRoutes(subService === undefined && proteomicsLab)
            document.title = 'Core Labs - Proteomics'};
          if(id === 'genomics') {setDynamicRoutes(genomicsLab)
            document.title = 'Core Labs - Genomics'};

      }, [id, subService]);

      const CoreLabPages = ({page}) =>{
        if(page === 'services'){
            return <>{services ? <Services services={services && services[0]} id={id} /> : <Loader />}</>
        }
        else if (page === 'proteomicsServices') {
          return <>{subServices ? <ProteomicsServices subServices={subServices} subService={subService} /> : <Loader />}</>
        }        
        else if ((id === 'material' && page === 'techniques') || (id === 'material' && page === 'thin-film')) {
          return <>{techniques ? <Techniques techniques={techniques} page={page} /> : <Loader />}</>
        }        
        else if(id === 'proteomics' && page === 'output')
        {
            return <>{output ? <Output output={output} pastEvents={pastEvents} /> : <Loader /> }</>
        }
        else if(page === 'about')
        {
            return <>{about ? <AboutUs about={about && about[0]} /> : <Loader /> }</>
        }
        else if(page === 'contact'){
            return <>{contact ? <ContactUs contact={contact && contact[0]} /> : <Loader /> }</>
        }
        else{
          history.push('/research/core-labs');
          return null;
        }
      }
      
  return (
    <>
      <Navigation {...headerData} navigationIntern={[]} updateLang={updateLang} />
      <ErrorBoundary>
        <CoreLabPages page={page} />
      </ErrorBoundary>
      {/* <NewsBanner {...HeaderBanner} coreLab={true} /> */}
      <Footer {...footerEn} />
    </>
  )
}

export default MasterCoreLabs;