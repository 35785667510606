import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import Footer from '../components/Footer'
import Navigation from '../components/Navigation'
import { footerEn } from '../data/footer'
import { researchData } from "../data/researchHighlights"
import { navigationButtom, navigationTop } from '../params'

import '../assets/scss/Funding.scss'

const navigationIntern = [
  { text: 'Colleges', hash: '/research/colleges', isActive: false},
  { text: 'Research Institutes', hash: '/research/institutes', isActive: false},
  { text: 'Research Highlights', hash: '/projects', isActive: true},
  
]

const crumb = [
  { link: '//', text: 'Home' },
  { link: '/', text: 'Research' },
  { link: '/research/funding', text: 'Funding' },
]

class ResearchHighlights extends Component{

   constructor(props) {
    super(props)
    this.state = {
      textInput:'',
      selectedFunding:'',
      selectedEntity:'',
      AdvancedShow:false,
      activeId : "2",
      thumbView:"",
      GridListIcon : [
      {
        GridListClass: 'grid-btn',
        GridListIconClass: 'fas fa-th-large',
        id:'1'
      },
      {
        GridListClass: 'list-btn',
        GridListIconClass: 'fas fa-bars',
        id:'2'
      }
      ], 
      FilterData: researchData.map(el => ({
        ...el, 
        searchString:el.title + " "+ el.pli[0].name
      })),
      AllData: researchData.map(el => ({
        ...el, 
        searchString:el.title + " "+ el.pli[0].name
      })),
      textSerachData:[],
      searchFundingData:[],
      searchEntityData:[],
      searchFocusAreaData: [],
    }
  }

    
  InputChangeHandler = (event) => {
    this.setState({
      textInput:event.target.value
    })
    let searchText = event.target.value.toLowerCase()
    if (searchText.length >= 3) {

      let allListItems = this.state.FilterData;
      let FilterItems = allListItems.filter(item =>
        item.searchString.toLowerCase().includes(searchText)
      );
      this.setState({
        textSerach: searchText,
        FilterData: FilterItems
      });
    }
     else {
     this.performSearch(event)
    }

  }

  performSearch(event){
  
    let searchText = this.state.textInput.toLowerCase();
    let searchFudning = this.state.selectedFunding.toLocaleLowerCase();
    let serachEntity = this.state.selectedEntity.toLocaleLowerCase();
    let allListItems = this.state.AllData;
    var FilterItems = allListItems

    if (searchText.length >= 3) {
       allListItems = this.state.FilterData;
       FilterItems = FilterItems.filter(item =>
        item.searchString.toLowerCase().includes(searchText)
      );
    }

 
    if (serachEntity.length > 0) { 
      FilterItems = FilterItems.filter(item =>
       item.Entity[0].toLowerCase().includes(serachEntity)
     );
   } 


  if (searchFudning.length > 0) { 
     FilterItems = FilterItems.filter(item =>
      item.Funding[0].type.toLowerCase().includes(searchFudning)
    );
  } 

  this.setState({
    FilterData: FilterItems
  });

  }


  handleChangeFunding = (event) => {
    this.setState({ 
      selectedFunding: event.target.value,
    });

  };

  handleChangeEntity = (event) =>{
    this.setState({ selectedEntity: event.target.value });
    
  }

  clearSearch(){
    this.setState({
      FilterData: this.state.AllData,
      textInput:'',
      selectedFunding:'',
      selectedEntity:'',
    });
  }

  handleClick(event, id) {
    this.setState({ 
      activeId : event
     })
    if(event === '1'){
      this.setState({ 
        thumbView : "news--grid"
      })
    }else{
      this.setState({ 
        thumbView : ""
      })
    }
  }

  render(){
  const { GridListIcon } = this.state;
  document.title = 'Research Funding - HBKU'
  const headerData = {
    title: 'Research at HBKU',
    crumb: crumb,
    navigationButtom: navigationButtom,
    navigationTop: navigationTop,
    applicationLinks: [{ text: 'Apply now', class: 'bordred', link: '/header/applynow' }],
  }

const highlightsCount = this.state.FilterData.length;
  return (
    <>
      <Navigation
        navigationIntern={navigationIntern}
        {...headerData}
        updateLang={this.props.updateLang}
      />
      
      <section id='Highlights' className="m-wrap filter-news">
          <div className='container'>
          <h2 className="text-hbku-primary p-b-3">Research Highlights</h2>
            <div className={"mez news-content news--list mez-pointer " + this.state.thumbView}>
              <div className="mez news-filter">
                <div className="filter-entry ">
                      <div className="searchAdvanced--filter">
                        <div className="SelectList">
                          <label><span>Search</span></label>
                           <input className="SearchAdvanced-input--Field"
                            placeholder="Search by Project Name or Project PLI" type="text" onChange={this.InputChangeHandler} value={this.state.textInput} />
                        </div>
                        <div className="SelectList">
                          <label><span>HBKU Entity</span></label>
                          <select onChange={this.handleChangeEntity} value={this.state.selectedEntity}> 
                            <option value="">-Any-</option>
                            <option value="CIS">College of Islamic Studies</option>
                            <option value="CHSS">College of Humanities & Social Sciences </option>
                            <option value="CSE">College of Science & Engineering</option>
                            <option value="CHLS">College of Health & Life Sciences</option>
                            <option value="CPP">College of Public Policy</option>
                            <option value="CL">College of Law</option>
                            <option value="QEERI">Qatar Environment and Energy Research Institute</option>
                            <option value="QBRI">Qatar Biomedical Research Institute</option>
                            <option value="QCRI">Qatar Computing Research Institute</option>
                          </select>
                        </div>
                        <div className="SelectList">
                          <label><span>Funding</span></label>
                          <select onChange={this.handleChangeFunding} value={this.state.selectedFunding}>
                            <option value="">-Any-</option>
                            <option value="External">External</option>
                            <option value="Internal">Internal</option>
                          </select>
                        </div>
                        <div className="SelectList">
                          <label><span>Focus Area</span></label>
                          <select>
                            <option value="">-Any-</option>
                            <option>AI</option>
                            <option>Sustainability</option>
                            <option>Precision Medicine</option>
                            <option>Social Progress</option>
                            <option>Disruptive Education</option>
                          </select>
                        </div>
                        <div className="SelectList">
                          <label><span>  &nbsp;</span></label>
                          <button className="SearchAdvanced-input--submitButton btn btn-outline btn-primary" onClick={this.clearSearch.bind(this)} type="submit">Clear search</button>
                        </div>
                        <div className="SelectList search-btn">
                          <label><span>  &nbsp;</span></label>
                          <button className="SearchAdvanced-input--submitButton btn btn-primary" onClick={this.performSearch.bind(this)} type="submit">Search</button>
                        </div>
                        
                      </div>

                      {/* <div className="checkboxAdvanced--filter m-t-2 m-b-2">
                        
                        <h5 className="text-hbku-primary m-b-2 cursor-pointer d-inline-block" 
                        onClick={() => {
                            this.setState({
                                AdvancedShow:!this.state.AdvancedShow
                            })
                          }}>Advanced Filter <i className={this.state.AdvancedShow ? "far fa-minus-square" : "far fa-plus-square"} style={{fontSize:' 18px', paddingLeft: '5px', fontWeight: 'normal'}}></i></h5>
                        <div className={this.state.AdvancedShow ? "show" : "d-none"} >
                        <div className="checkboxlist">
                          <label className="checkboxlist_row">Excellence
                            <input type="checkbox" />
                            <span className="checkmark"></span>
                          </label>
                          <label className="checkboxlist_row">Partnerships
                            <input type="checkbox" />
                            <span className="checkmark"></span>
                          </label>
                          <label className="checkboxlist_row">Innovation
                            <input type="checkbox" />
                            <span className="checkmark"></span>
                          </label>
                          <label className="checkboxlist_row">Research Impact
                            <input type="checkbox" />
                            <span className="checkmark"></span>
                          </label>
                          <label className="checkboxlist_row">Community Impact
                            <input type="checkbox" />
                            <span className="checkmark"></span>
                          </label>
                          <label className="checkboxlist_row">Lacal to International
                            <input type="checkbox" />
                            <span className="checkmark"></span>
                          </label>
                           
                        </div>
                        </div>
                      </div> */}
                    </div>
              </div>

							<div className="mez calendar-head m-t-3">
                <h1><span>{highlightsCount} matching results</span></h1> 
								<div className="l-view-wrap">
                  {/* <ul>
                    <li className="pdfdownlad"> <a href="#"> <i className="far fa-file-pdf"></i>
											<span>Download <br /> PDF </span> </a>
										</li>
									</ul> */}
									<ul>
										{GridListIcon.map(list =>
                      <li key={ list.id } className={this.state.activeId === list.id ? "active" : null}
            onClick={ this.handleClick.bind(this, list.id) } >
											  <i className={list.GridListIconClass}></i>
										  </li>                    
                    )}

									</ul>
								</div>
							</div>
							<div className="news__block">
                
								<ul>
                {this.state.FilterData.map((e, k) => (
									<li className="items" key={e.id}>
										<div className="mez thumb-block">
											<figure>
												<Link to={"/projects/"+e.id} className="full-height">
                          {/* <span className="hbku-tags m-b-0">QBRI</span> */}
                          <img src={e.image} alt="" title="" className="img-responsive full-width" />
                        {/* <div classN="save-tags m-b-0"><span className="tags_bookmark tags">Save <i className="far fa-bookmark "></i></span> 
																				</div> */}
                        </Link>
											</figure>
											<div className="thumb-block__detail">
												<div className="thumb-block__wrap">
													<h2>	<Link to={"/projects/"+e.id} >{e.title}</Link></h2>
													<div className="tag-maker">
                          {e.tags.map((tag, tk) => (
                            <span key={tk} >
                            { tag.link ? (<a href={tag.link} target="_blank" rel="noreferrer">{tag.name}</a> ):(tag.name)}
                            </span>
                           ))}
                            </div>
                            <div className="thumb-block-des">
                                {e.ContentShort.map((el, ke) => (
                                <p key={ke}>{el}</p>
                                ))}
                            </div>
												</div>
                        <div className="thumb-block__bottom">
                        {e.pli.map((pliData, pk) => (
                          <div className="news-lecturer" key={pk}>
                            {pliData.link?(
                                <>
                                <figure className="lecturer--img">
                               <a href={pliData.link} target="_blank" rel="noreferrer">
                               {pliData.avator?(
                                      <img className="img-circle" src={pliData.avator} alt={pliData.name} />
                                  ):(
                                    <img className="img-circle" src='/uploads/user-pm.jpg' alt={pliData.name} />
                                  )}
                                </a>
                            </figure>
                            <div className="lecturer__info">
                               <a href={pliData.link} target="_blank" rel="noreferrer">
                                  <h5 className="lecturer-name p-t-1">
                                    {pliData.name}
                                  </h5>
                                </a>
                            </div>
                                </>
                                ):(
                                  <>
                                  <figure className="lecturer--img">
                               
                               {pliData.avator?(
                                      <img className="img-circle" src={pliData.avator} alt={pliData.name} />
                                  ):(
                                    <img className="img-circle" src='/uploads/user-pm.jpg' alt={pliData.name} />
                                  )}
                                
                            </figure>
                            <div className="lecturer__info">
                              
                                  <h5 className="lecturer-name p-t-1">
                                    {pliData.name}
                                  </h5>
                               
                            </div>
                                  </>
                                )}
                            
                            
                        </div>
                        ))}
                    
                          <Link to={"/projects/"+e.id} className="l-btn read-more">Read more</Link>
                        </div>
											</div>
										</div>
									</li>
                ))}
                {this.state.FilterData.length < 1?(
                  <p className="no-data ">No result found.</p>
                ):('')}
								</ul>
							</div>
						</div>
        </div>
      </section>
       
      
      <Footer {...footerEn} />
    </>
  ) }
}

export default ResearchHighlights