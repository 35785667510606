import React from 'react';
import { Link } from 'react-router-dom';
import '../../assets/scss/CoreLabsAboutUs.scss';
import '../../assets/scss/Compliance.scss';

const Services = ({ services, id }) => {

  return (
    <>
      {
        id === 'proteomics' &&
        <>
          <section className='m-wrap'>
            <div className='container'>
              <div dangerouslySetInnerHTML={{ __html: services?.Overview }} />
            </div>
          </section>
          <section className="mb-0">
            <div className="container">
              <h2 className="text-hbku-primary p-b-2">Our Services</h2>
              <div className="l-article">
                <ul className="m-b-0">
                  <li className="p-b-1 fw-bolder"><Link to="/core-labs/proteomics/services/olink">OLINK Platforms</Link></li>
                  <li className="p-b-1 fw-bolder"><Link to="/core-labs/proteomics/services/sengenics">Sengenics Technology: Autoantibody Biomarker Discovery Array</Link></li>
                  <li className="p-b-1 fw-bolder"><Link to="/core-labs/proteomics/services/huport">HuProt Technology: CDI lab array</Link></li>
                  <li className="p-b-1 fw-bolder"><Link to="/core-labs/proteomics/services/simoa">SIMOA Technology</Link></li>
                </ul>
              </div>
            </div>
          </section>
          <section className="m-wrap services">
            <div className='container'>
              <h2 className="text-hbku-primary p-b-1">Instruments</h2>
              <div className="row">
                {
                  services && services?.field_corelab_accordian?.map((data, index) => {
                    return (
                      <div className="col-md-4 col-lg-3 mb-4" key={index}>
                        <div className="card" style={{ minHeight: "25rem" }}>
                          <img className="card-img-top" src={data?.image_link?.value} alt="Card image cap" />
                          <div className="card-body d-flex justify-content-center align-items-center">
                            <h5 className="card-title text-hbku-primary text-center">{data?.Question?.value}</h5>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </section>
        </>
      }
      {
        id === 'material' &&
        <section className='m-wrap'>
          <div dangerouslySetInnerHTML={{ __html: services?.body_1?.value }} />
        </section>
      }
      {
        id === 'genomics' &&
        <section className='m-wrap'>
          <div className='container'>
            <div dangerouslySetInnerHTML={{ __html: services?.body_1?.value }} />
            <div className='news__block m-t-3'>
              <ul>
                {services && services?.field_corelab_accordian?.map((lab, index) => {
                  return (
                    <li className="items techniques_card" key={index}>
                      <div className="mez thumb-block">
                        <figure>
                          <img src={lab?.image_link?.value} alt="" title="" className="img-responsive full-width" />
                        </figure>
                        <div className="thumb-block__detail" style={{ paddingBottom: 0 }}>
                          <div className="thumb-block__wrap">
                            <h2>{lab?.Question?.value}</h2>
                          </div>
                          <div className="thumb-block-des">
                            <div dangerouslySetInnerHTML={{ __html: lab?.Answer?.value }} />
                            <div className='py-3'>
                              <a href={lab?.pdf_link?.value} target="_blank" rel="noopener noreferrer" className='l-btn read-more fw-bolder text-decoration-none' style={{ color: '#0088CE', position: 'relative', paddingTop: 0 }}>
                                Learn More <i className="ps-1 fas fa-arrow-right"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  )
                })

                }
              </ul>
            </div>
          </div>
        </section>
      }
    </>
  );
};

export default Services;
