import '../../assets/scss/Compliance.scss'

const IBC = () => {
    return(
        <>
        <section className="m-wrap irb-ibc">
          <div className='container'>
            <h2 className="text-hbku-primary p-b-3">Institutional Biosafety Committee (IBC)</h2>
            <p>The Hamad Bin Khalifa University Institutional Biosafety Committee (HBKU-IBC) is constituted to develop and implement institutional biosafety policies and codes of practice. The IBC has the responsibility to ensure that all biomedical research activities involving hazardous materials that may have the capacity to produce adverse effects on human beings, animals or the environment are conducted in a safe manner and in line with applicable regulations. </p>
            <p>The IBC reviews research protocols for work involving research involving infectious agents, animal use, recombinant or synthetic nucleic acid and genetically modified materials. Other functions of the committee may include risk assessments, formulation of new safety policies (in coordination with Institutional Effectiveness Office) and arbitration in disputes over biosafety matters. The IBC carries out the annual inspection of institutional facilities and maintains a register of approved projects that are periodically reviewed to ensure compliance with the containment facilities (e.g. laboratories, animal houses, etc.) as well as the personnel qualification and training records.</p>
            <p>The HBKU-IBC’s responsibility is limited to the HBKU colleges and research institutes, as well as any other organization that relies on HBKU-IBC via a reliance agreement. The IBC aims to review and approve research protocols conducted at or sponsored by these entities for compliance with the Governmental and International Biosafety Guidelines as approved by the Ministry of Public Health <a target='_blank' href='https://research.moph.gov.qa/en/Pages/BiosafetyResearch.aspx?csrt=7054370316874518422'>(MoPH)</a>.</p>

            {/* <p> <strong>Important Notice: Summer Review Delays (June-August) <br></br>The HBKU-IBC members will be traveling intermittently during the summer. As such, there will be delays in reviewing new submissions, with the review timeframe extended to approximately 5 weeks. Thank you for your understanding.</strong></p> */}

            <div className="l-article l-accordion l-accordion4">
                <div className="mez slider">
                    <h3 className="l-accordion__title">
                        <span>How to apply</span>
                    </h3>
                    <div className="l-accordion__content">
                    <ul className="mb-5">
                        <li>For IBC submissions, click this <a href='https://signin.app.cayuse.com/login?tenant_id=aa06a39b-8894-40dc-88c4-07ee4e9f0e31&redirect_uri=https%3A%2F%2Fevmcr4.app.cayuse.com%2Fauthentication&response_type=CODE&state&nonce&auth_error=false' target='_blank'>link</a> and use your login credentials.</li>
                        <li>For login credentials please contact <a href='mailto:hbkuibc@hbku.edu.qa'>hbkuibc@hbku.edu.qa </a></li>
                    </ul>
                    </div>
                    
                    <h3 className="l-accordion__title">
                        <span>Documents</span>
                    </h3>
                    <div className="l-accordion__content">
                    <ul className="mb-5">
                        <li><a href="https://www.hbku.edu.qa/sites/default/files/ibc_guidelines_for_core_labs.pdf" target="_blank"> IBC Guidelines for Core Labs</a></li>
                        <li><a href="https://www.hbku.edu.qa/sites/default/files/ibc_exemption_checklist.pdf" target="_blank"> IBC Exemption Checklist</a></li>
                        <li><a href="https://www.hbku.edu.qa/sites/default/files/ibc_group_protocols.pdf" target="_blank"> IBC Group Protocols</a></li>
                        {/* <li><a href="https://www.hbku.edu.qa/sites/default/files/ibc_closure_form_template.pdf" target="_blank"> IBC Closure Form</a></li> */}
                        <li><a href="https://www.hbku.edu.qa/sites/default/files/ibc_closure_form_template_updated.docx" target="_blank"> IBC Closure Form</a></li>
                        
                    </ul>
                    </div>
                    
                </div>
            </div>
            <h3 className="text-hbku-primary p-b-2 p-t-2">HBKU-IBC Members</h3>
            <p>HBKU-IBC members are comprised of representatives of biosafety officers, laboratory technical staff and scientists with varying backgrounds of research expertise: </p>
            <div className="filter-container">
                <table className="views-table full-width" style={{backgroundColor: '#ffffff'}}>
                    <thead>
                        <tr>
                            <th className="f-project-title">Name </th>
                            <th className="f-project-lead">Entity </th>
                            <th className="f-project-entity">Role </th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr className="even">
                        <td>Dr. Prasanna Kolatkar</td>
                        <td>HBKU QBRI</td>
                        <td>Chair</td>
                    </tr>
                    <tr className="odd">
                        <td>Dr. Salah Mogith</td>
                        <td>Sidra Medicine</td>
                        <td>Deputy Chair</td>
                    </tr>
                    <tr className="even">
                        <td>Dr. Abdulghani Khilan</td>
                        <td>HBKU</td>
                        <td>Biosafety Officer</td>
                    </tr>
                    <tr className="odd">
                        <td>Dr. Matteo Avella</td>
                        <td>Sidra Medicine</td>
                        <td>Member</td>
                    </tr>
                    <tr className="even">
                        <td>Dr. Ajaz Bhat</td>
                        <td>Sidra Medicine</td>
                        <td>Member</td>
                    </tr>
                    <tr className="odd">
                        <td>Mr. Carl Bjorkhammer</td>
                        <td>Sidra Medicine</td>
                        <td>Member</td>
                    </tr>
                    <tr className="even">
                        <td>Dr. Nady El Hajj</td>
                        <td>HBKU CHLS</td>
                        <td>Member</td>
                    </tr>
                    <tr className="odd">
                        <td>Dr. Yongsoo Park</td>
                        <td>HBKU QBRI</td>
                        <td>Member</td>
                    </tr>
                    <tr className="even">
                        <td>Dr. Dindial Ramotar</td>
                        <td>HBKU CHLS</td>
                        <td>Member</td>
                    </tr>
                     <tr className="odd">
                        <td>Dr. Julia Reichelt</td>
                        <td>HMC</td>
                        <td>Member</td>
                    </tr>
                    <tr className="even">
                        <td>Dr. Nandakumar Pillai</td>
                        <td>QF</td>
                        <td>Member</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <br /> <br />
            <h3 className="text-hbku-primary p-b-2 p-t-2">IBC Meetings 2025</h3>
            <p>IBC meetings typically take place on the second Monday of every month</p>
            {/* <div className="filter-container">
                <table className="views-table full-width" style={{backgroundColor: '#ffffff'}}>
                    <thead>
                        <tr>
                            <th className="f-project-title">Month </th>
                            <th className="f-project-lead">Submission Deadline </th>
                            <th className="f-project-entity">Meeting Dates </th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr className="odd">
                        <td>March</td>
                        <td>27 February</td>
                        <td>13 March</td>
                    </tr>
                    <tr className="even">
                        <td>April</td>
                        <td className="text-center">Ramadan and Eid*</td>
                        <td> &nbsp; </td>
                    </tr>
                    <tr className="odd">
                        <td>May</td>
                        <td>1 May</td>
                        <td>15 May</td>
                    </tr>
                    <tr className="even">
                        <td>June</td>
                        <td>29 May</td>
                        <td>12 June</td>
                    </tr>
                    <tr className="odd">
                        <td>July</td>
                        <td>26 June</td>
                        <td>10 July</td>
                    </tr>
                    <tr className="even">
                        <td>August</td>
                        <td>31 July</td>
                        <td>14 August</td>
                    </tr>
                    <tr className="odd">
                        <td>September</td>
                        <td>28 August</td>
                        <td>11 September</td>
                    </tr>
                    <tr className="even">
                        <td>October</td>
                        <td>2 October</td>
                        <td>16 October</td>
                    </tr>
                    <tr className="odd">
                        <td>November</td>
                        <td>30 October</td>
                        <td>13 November</td>
                    </tr>
                    <tr className="even">
                        <td>December</td>
                        <td>27 November</td>
                        <td>11 December</td>
                    </tr>
                    </tbody>
                </table>
                <br></br>
            </div> */}
            <p><strong>Kindy be advised: </strong> IBC submissions are reviewed as received. Complete applications must be submitted by the “Submission Deadline” for consideration at the corresponding meeting. Meeting dates are tentative upon achieving quorum for each meeting scheduled.</p>
            <br /> <br />
           </div>
        </section>
        </>
        )
    }
export default IBC;