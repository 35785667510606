import { Link } from 'react-router-dom'
import {  useParams } from 'react-router-dom'

import Footer from '../components/Footer'
import ScrollToTop from '../components/Generic/ScrollToTop'

import ModalGallery from '../components/ImageSlider/ModalGallery'
import Navigation from '../components/Navigation'
import { footerEn } from '../data/footer'
import { researchData } from "../data/researchHighlights";
import { navigationButtom, navigationTop } from '../params'

import '../assets/scss/NewsDetails.scss'

const navigationIntern = [
  // { text: 'Funding', hash: '#funding-list' },
  // { text: 'Research highlights', hash: '#research-highlights' },
  // { text: 'Filter News', hash: '#filter-news' },
  
]

const crumb = [
  { link: '/', text: 'Home' },
  { link: '/research', text: 'Research' },
  { link: '/projects', text: 'Research highlights' },
]

function NewsDetails(props) {
  const { ResearchID } = useParams()
  document.title = 'Research News Details - HBKU'
  const headerData = {
    title: 'Research at HBKU',
    crumb: crumb,
    navigationButtom: navigationButtom,
    navigationTop: navigationTop,
    applicationLinks: [{ text: 'Apply now', class: 'bordred', link: '/header/applynow' }],
  }

  let dataObj = researchData.find(o => o.id === ResearchID);

  return (
    <>
      <Navigation
        navigationIntern={navigationIntern}
        {...headerData}
        updateLang={props.updateLang}
      />
    <ScrollToTop />
      <section id='research-highlights' className="m-b-0 news-details">
          <div className="mez news-details-content">
						<div className="mez news-details-head">
              <div className='container'>
                  <div className="row">
                    <div className="col-12 col-md-9  padding-right-40">
                      <div className="news-details-head__left">
                        <ul className="breadcrumb last-active">
                          <li><Link to="/">Home</Link></li>
                          <li><Link to="/research/colleges">Research</Link></li>
                          <li><Link to="/projects">Research highlights</Link></li>
                      </ul>
                      <h1>{dataObj.title}</h1>
                      </div>
                    </div>
                    <div className="col-12 col-md-3">
                      <div className="news-details-head__right">
                        <div className="dateinfo">
                          {/* <h5><span> Publishing Date:</span>  24 Sep 2021 </h5> */}
                        </div>
                        {/* <div className="shareBtn"> <a href="#" className="more"> <i className="fas fa-share"></i> Share </a></div> */}
                      </div>
                    </div>
                  </div>
							</div>
						</div>

            <div className="news-details-body m-wrap">
              <div className='container'>
                <div className="row">
                  <div className="col-12 col-md-7">
                    <div className="card-block">
                      <div className="project-details">
                        <h3>Project Details</h3>
                        <ul>
                          <li><span>Project Start Date: </span>{dataObj.StartDate}</li>
                          <li><span>Project End Date: </span>{dataObj.EndDate}</li>
                          <li><span>Funding Source: </span> {dataObj.Funding[0].name} </li>
                        </ul>
                      </div>
                    </div>
                    <div className="card-block">
                        <div className="project-summary">
                           <h3>Project Summary</h3>
                           {dataObj.ContentFull.map((el, ke) => (
                                <p key={ke}>{el}</p>
                                ))}
                           </div>
                    </div>

                   {/* <div className="card-block">
                        <div className="project-summary project-gallery">
                           <h3>Photos, schematics, graphs.....etc.</h3>
                           <ImageSlider />
                           
                        </div>
                    </div> */}

                    <ModalGallery Items={dataObj.gallery} />
                    

                    {/* <div className="card-block">
                        <div className="project-summary">
                           <h3>Project Objectives</h3>
                           <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris</p> <p> nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. </p>
                           <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. </p>
                        </div>
                    </div>
                    <div className="card-block">
                        <div className="project-summary">
                           <h3>Up-to-date achievements</h3>
                           <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. </p>
                        </div>
                    </div> */}
                  </div>
                  <div className="col-12 col-md-5">
                    <div className="card-block">
                      <div className="project-investigator"> 
                        <h3>Lead Principal Investigator</h3>
                        <div className="block-profile">
                          <ul className="block-profile__list">
                          {dataObj.pli.map((pliData, pk) => (
                              <li className={"block-profile__list--item "+pliData.addExtraClass} key={pk}>
                                  <div className="profile--img">
                                  {pliData.avator?(
                                      <img src={pliData.avator} alt={pliData.name} />
                                  ):(
                                    <img src='/uploads/user-pm.jpg' alt={pliData.name} />
                                  )}
                                  </div>
                                 
                                  <div className="profile-details" key={pk}>
                                  <h4 className="person-name">{pliData.name}</h4>
                                  {pliData.link?(
                                    <div className="person-designation">
                                      <a href={pliData.link} target="_blank" rel="noreferrer"> {pliData.designation}</a>
                                      </div>
                                  ):(
                                <div className="person-designation">
                                  {pliData.designation}
                                  </div>
                                  )}
                                  {pliData.college.length>0?(
                                    <div className="person-col"><a href={pliData.college[0].link} target="_blank" rel="noreferrer"> {pliData.college[0].name} </a></div>
                                  ):(<></>)}
                              </div>    
                              </li>
                               ))}
                          </ul>
                      </div>
                      </div>
                    </div>
                    <div className="card-block">
                      <div className="project-investigator">
                         <h3>Other Pls</h3>
                         <div className="block-profile">
                          <ul className="block-profile__list">
                          {dataObj.ProjectTeams.map((team, tk) => (
                            <li className="block-profile__list--item" key={tk}>
                                  <div className="profile--img">
                                  {team.avator?(
                                      <img src={team.avator} alt={team.name} />
                                  ):(
                                    <img src='/uploads/user-pm.jpg' alt={team.name} />
                                  )}
                                   
                                  </div>
                                  <div className="profile-details">
                                      <h4 className="person-name">{team.name}</h4>

                                      {team.link?(
                                    <div className="person-designation">
                                      <a href={team.link} target="_blank" rel="noreferrer"> {team.designation}</a>
                                      </div>
                                  ):(
                                <div className="person-designation">
                                  {team.designation}
                                  </div>
                                  )}
                                  {team.college.length>0?(
                                    <div className="person-col"><a href={team.college[0].link} target="_blank" rel="noreferrer"> {team.college[0].name} </a></div>
                                  ):(<></>)}
                                  
                                      {/* <div className="person-designation"><a href="#"> Professor </a></div>
                                      <div className="person-col"><a href="#">College of Public Policy </a></div> */}
                                  </div>
                              </li>

                          ))}
                              
                          </ul>
                      </div>
                      </div>
                    </div>
                    {/* <div className="card-block">
                      <div className="project-video">
                        <h3>Video</h3>
                        <div className="cl2019quote-info__video">
                        <video controls="controls" muted loop controlslist="nodownload"
                          poster="https://www.hbku.edu.qa/sites/default/files/video-poster/edx_sustainability_and_major_sports_events.jpg" className="full-width">
                          <source
                            src="https://www.hbku.edu.qa/sites/default/files/videos/edx_sustainability_and_major_sports_events_tw_1280x720.mp4"
                            type="video/mp4" className="full-width" />
                        </video>
                      </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
        </div>
      </section>
       
      
      <Footer {...footerEn} />
    </>
  )
}

export default NewsDetails
