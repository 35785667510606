import Footer from '../components/Footer'
import Navigation from '../components/Navigation'
import { footerEn } from '../data/footer'
import { navigationButtom, navigationTop } from '../params'

import '../assets/scss/Funding.scss'

const navigationIntern = [
  // { text: 'Funding', hash: '#funding-list' },
  // { text: 'Funding', hash: '#funding-news', isActive: true },
  // { text: 'Filter News', hash: '#filter-news' },
  
]

const crumb = [
  { link: '//', text: 'Home' },
  { link: '/', text: 'Research' },
  { link: '/research/funding', text: 'Funding' },
]


function Funding(props) {
  document.title = 'Research Funding - HBKU'
  const headerData = {
    title: 'Research at HBKU',
    crumb: crumb,
    navigationButtom: navigationButtom,
    navigationTop: navigationTop,
    applicationLinks: [{ text: 'Apply now', class: 'bordred', link: '/header/applynow' }],
  }
  return (
    <>
      <Navigation
        navigationIntern={navigationIntern}
        {...headerData}
        updateLang={props.updateLang}
      />
      {/* <section id='funding-list' className="m-wrap funding-list">
        <div className='container'>
          <h2 className="text-hbku-primary p-b-3">Funding List </h2>
          <div className='funding-list-info'>
              <ul>
                <li>
                  <img src="/uploads/f-sponsors-icon.png" alt={'Sponsors'} />
                  <span>13</span>
                  <h4>Sponsors</h4>
                </li>
                <li>
                  <img src="/uploads/f-institutional-collaborations-icon.png" alt={'Institutional Collaborations'} />
                  <span>139</span>
                  <h4>Institutional <br /> Collaborations</h4>
                </li>
                <li>
                  <img src="/uploads/f-research-aagreements-icon.png" alt={'Number of Research'} />
                  <span>358</span>
                  <h4>Number of Research <br /> Aagreements </h4>
                </li>
                <li>
                  <img src="/uploads/f-publications-icon.png" alt={'Publications'} />
                  <span>438</span>
                  <h4>Publications</h4>
                </li>
                <li>
                  <img src="/uploads/f-patents-icon.png" alt={'Patents'} />
                  <span>01</span>
                  <h4>Patents</h4>
                </li>
                <li>
                  <img src="/uploads/f-invention-disclosure-icon.png" alt={'Invention disclosure'} />
                  <span>81</span>
                  <h4>Invention <br /> disclosure</h4>
                </li>
              </ul>
            </div>
        </div>
      </section> */}

      <section id='funding-news' className="m-wrap funding-news">
        <div className='container'>
          <h2 className="text-hbku-primary p-b-3">Funding</h2>
        <div className='funding-news-info'>
            <div className="dieBlock7__block">
              <div className="dieBlock7__block__list">
                <a href="https://www.hbku.edu.qa/en/thematic-research-grant-program" target="_blank" rel="noreferrer">
                  <div className="dieBlock7--image">
                    <figure className="">
                      <img src="/uploads/Thematic-Research-Grants.png"
                        alt="" className="full-width img-responsive" />
                        <figcaption>Hamad Bin Khalifa University’s (HBKU) Thematic Research Grant Program is funded by the Office of the Vice President for Research (OVPR)</figcaption>
                    </figure>
                    
                  </div>
                  <div className="dieBlock7__content-info">
                    <div className="dieBlock7--header p-b-0 m-b-0">
                      Thematic Research <br /> Grants
                    </div>
                    
                  </div>
                </a>
              </div>
              <div className="dieBlock7__block__list">
                <a href="https://www.hbku.edu.qa/en/research/sro" target="_blank" rel="noreferrer">
                  <div className="dieBlock7--image">
                    <figure className="">
                      <img src="/uploads/Sponsored-Research-Grants.png"
                        alt="" className="full-width img-responsive" />
                         <figcaption>The Sponsored Research Office (SRO) contributes to Hamad Bin Khalifa University’s (HBKU’s) academic and research mission through the provision of pre and post-award management for all sponsored research projects. </figcaption>
                    </figure>
                    
                  </div>
                  <div className="dieBlock7__content-info">
                    <div className="dieBlock7--header p-b-0 m-b-0">Sponsored Research <br /> Office
                    </div>

                  </div>
                </a>
              </div>
              <div className="dieBlock7__block__list">
                <a href="https://www.hbku.edu.qa/en/innovation-center" target="_blank" rel="noreferrer">
                  <div className="dieBlock7--image">
                    <figure className="">
                      <img src="/uploads/Innovation-Grants.png"
                        alt="" className="full-width img-responsive" />
                         <figcaption>Hamad Bin Khalifa University’s (HBKU’s) Innovation Center (HBKU IC) is a pioneering initiative designed to foster entrepreneurship and creativity both within and outside HBKU.</figcaption>
                    </figure>
                    
                  </div>
                  <div className="dieBlock7__content-info">
                    <div className="dieBlock7--header p-b-0 m-b-0">Innovation Grants
                    </div>

                  </div>
                </a>
              </div>

              <div className="dieBlock7__block__list">
                <a href="https://www.hbku.edu.qa/en/research/srp" target="_blank" rel="noreferrer">
                  <div className="dieBlock7--image">
                    <figure className="">
                      <img src="/uploads/srp.jpg"
                        alt="" className="full-width img-responsive" />
                         <figcaption>The Signature Research Program (SRP) is a new platform launched by the Office of the Vice President for Research (OVPR) aimed at promoting innovative and impactful research through cross-entity interdisciplinary collaboration within HBKU and beyond.</figcaption>
                    </figure>
                    
                  </div>
                  <div className="dieBlock7__content-info">
                    <div className="dieBlock7--header p-b-0 m-b-0">Signature Research Program
                    </div>

                  </div>
                </a>
              </div>

            </div>
        </div>
      </div>
    </section> 
      <Footer {...footerEn} />
    </>
  )
}

export default Funding
