import React, { Component } from 'react'

import "../assets/scss/ContactUsBlock.scss"

class ContactUsBlock extends Component {
  state={
    ContactUsContent:[
      {
        title: 'The Office of the Vice President for Research',
        phone: '',
        email:'researchsupport@hbku.edu.qa ',
        address: 'P.O. Box: 34110, Doha – Qatar',
        btntext: 'Go to the Form',
        id:'1'
      },
      {
        title: 'College of Islamic Studies',
        phone: '',
        email:'cis@hbku.edu.qa ',
        address: 'P.O. Box: 34110, Doha – Qatar',
        btntext: 'Go to the Form',
        id:'2'
      },
      {
        title: 'College of Humanities and Social Sciences',
        phone: '',
        email:'chss@hbku.edu.qa',
        address: 'P.O. Box: 34110, Doha – Qatar',
        btntext: 'Go to the Form',
        id:'3'
      },
      {
        title: 'College of Science and Engineering',
        phone: '',
        email:'cse@hbku.edu.qa',
        address: 'P.O. Box: 34110, Doha – Qatar',
        btntext: 'Go to the Form',
        id:'4'
      },
      {
        title: 'College of Law',
        phone: '',
        email:'cl@hbku.edu.qa',
        address: 'P.O. Box: 34110, Doha – Qatar',
        btntext: 'Go to the Form',
        id:'5'
      },
      {
        title: 'College of Health and Life Sciences',
        phone: '',
        email:'chls@hbku.edu.qa',
        address: 'P.O. Box: 34110, Doha – Qatar',
        btntext: 'Go to the Form',
        id:'6'
      },
      {
        title: 'College of Public Policy',
        phone: '',
        email:'cpp@hbku.edu.qa',
        address: 'P.O. Box: 34110, Doha – Qatar',
        btntext: 'Go to the Form',
        id:'7'
      },
      {
        title: 'Qatar Biomedical Research Institute',
        phone: '+974 4454 8143',
        email:'qbriinfo@hbku.edu.qa',
        address: 'P.O Box 34110 Doha, Qatar',
        btntext: 'Go to the Form',
        id:'8'
      },
      {
        title: 'Qatar Computing Research Institute',
        phone: '+974 4454 0629',
        email:'qcriinfo@qf.org.qa',
        address: 'P.O Box 5825 Doha, Qatar',
        btntext: 'Go to the Form',
        id:'9'
      },
      {
        title: 'Qatar Environment and Energy Research Institute',
        phone: '',
        email:'qeeri-communication@hbku.edu.qa',
        address: 'P.O Box 34110 Doha, Qatar',
        btntext: 'Go to the Form',
        id:'10'
      },
    ]
  }
  render(){
    return (
        <section id='vpr-office-bio' className="m-wrap">
          <div className='container'>
            <div className='row'>
              <div className="col-12">
              {this.state.ContactUsContent.map((data , k) => (
                <div className={' contactus-block border border-primary m-b-3'} key={k}>
                    <div className="card-body">
                      <h5 className="card-title">{data.title}</h5>
                      <ul>
                        {data.phone ? <li><span><i className="fas fa-mobile-alt"></i></span>{data.phone} </li> : ''}
                        
                        <li><span><i className="far fa-envelope"></i> </span> <a href={"mailto:" + data.email} >{data.email} </a> </li>
                        <li><span><i className="fas fa-map-marker-alt"></i> </span> {data.address} </li>
                      </ul>
                      {/* <a href="/" className="btn btn-primary">{data.btntext}</a> */}
                    </div>
              </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    )
  }
    
}

export default ContactUsBlock