export const MEDIA_URL = 'https://api.boufaied.com/'
export const API_URL = 'https://api.boufaied.com/api'

export const navigationTop = [
  { text: 'About HBKU', link: 'https://www.hbku.edu.qa/en/about-hamad-bin-khalifa-university' },
  { text: 'Academics', link: 'https://www.hbku.edu.qa/en/academics' },
  { text: 'Innovation', link: 'https://innovation.hbku.edu.qa/' },
  { text: 'Research', link: '/research' },
  // { text: 'Admissions', link: 'https://www.hbku.edu.qa/en/admissions' },
  { text: 'University Life', link: 'https://www.hbku.edu.qa/en/life-hbku' },
]
export const navigationButtom = [
  { text: 'OVPR', link: '/research/office-vpr' },
  { text: 'Research', link: '/research/colleges' },
  // { text: 'Colleges', link: '/research/colleges' },
  { text: 'Funding', link: '/research/funding' },
  { text: 'Core Labs', link: '/research/core-labs' },
  { text: 'HBKU RMS', link: '/research/hbku-rms' },
  { text: 'Facilities', link: '/research/facilities-services' },
  // { text: 'News', link: '/research/news' },
  // { text: 'Research Day', redirect: true, link: 'https://research-day.hbku.edu.qa/' },
  { text: 'Compliance', link: '/research/compliance' },
  // { text: 'Contact Us', link: '/research/contact-us' },
  
]

// core labs routes
export const materialLab = [
  { text: 'Services', link: `/core-labs/material/services` },
  { text: 'Techniques', link: `/core-labs/material/techniques` },
  { text: 'Thin Film Deposition Lab', link: `/core-labs/material/thin-film` },
  { text: 'About', link: `/core-labs/material/about-us` },
  { text: 'Contact Us', link: `/core-labs/material/contact-us` },
]
export const proteomicsLab = [
  { text: 'Services', link: `/core-labs/proteomics/services` },
  { text: 'About', link: `/core-labs/proteomics/about-us` },
  { text: 'Output', link: `/core-labs/proteomics/output` },
  { text: 'Contact Us', link: `/core-labs/proteomics/contact-us` },
]
export const genomicsLab = [
  { text: 'Services', link: `/core-labs/genomics/services` },
  { text: 'About', link: `/core-labs/genomics/about-us` },
  { text: 'Contact Us', link: `/core-labs/genomics/contact-us` },
]
