import '../../assets/scss/banner.scss'

function NewsBanner(props) {
  return (
    <>
      { !props.coreLab ?
      <section dir={props.lang === 'ar' ? 'rtl' : 'ltr'}>
        <div className='container'>
          <div className='banner'>
            <div>
              <h2>{props.title}</h2>
              <p>{props.subtitle}</p>
            </div>

            <div className='custom-input-group'>
              <span className='append-group-text'>
                <img src='/uploads/mail.png' alt='mail icon' />
              </span>
              <input type='email' placeholder={props.placeholder} />
            
            </div>
          </div>
        </div>
      </section>
      :
      <div className='bg-blue m-wrap border-bottom core-lab-news'>
        <div className='container'>
          <div className='banner'>
            <div>
              <h2>{props.title}</h2>
              <p>{props.subtitle}</p>
            </div>

            <div className="input-group">
              <input type="email" className="form-control form-control-sm py-2" placeholder="Email Address" aria-label="Email Address" aria-describedby="button-addon2"/>
              <div className="input-group-append">
                <button className="btn btn-primary" type="button" id="button-addon2">Subscribe</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      }
    </>
  )
}
export default NewsBanner
