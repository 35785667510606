import React, { useState } from 'react';
// import { Link } from 'react-router-dom'

import Footer from '../components/Footer'
import Navigation from '../components/Navigation'
import WelecomeQuoteInline from '../components/Quotes/WelecomeQuoteInline'
import { footerEn } from '../data/footer'
import { navigationButtom, navigationTop } from '../params'

import '../assets/scss/VprOffice.scss'

const navigationIntern = [
  { text: 'About OVPR', hash: '#about-ovpr', isActive: true },
  // { text: 'Welcome message', hash: '#vpr-office' },
  // { text: 'VPR Bio', hash: '/research/office-vpr/biography#vpr-office-bio', icon:'fas fa-external-link-alt' },
]

const crumb = [
  { link: '/', text: 'Home' },
  { link: '/research', text: 'Research' },
  { link: '/research/about', text: 'VPR Office' },
]


function VPROffice(props) {
  
 // const [OvprSupportShow, setOvprSupportShow] = useState("OvprSupport")
  const [OvprSupportShow, setOvprSupportShow] = useState(false);


  document.title = 'VPR Office - HBKU'
  const headerData = {
    title: 'Research at HBKU',
    crumb: crumb,
    navigationButtom: navigationButtom,
    navigationTop: navigationTop,
    applicationLinks: [{ text: 'Apply now', class: 'bordred', link: '/header/applynow' }],
  }
  return (
    <>
      <Navigation
        navigationIntern={navigationIntern}
        {...headerData}
        updateLang={props.updateLang}
      />
      <section id='about-ovpr' className="m-wrap about-ovpr">
          <div className='container'>
          <h2 className="text-hbku-primary p-b-3">About OVPR</h2>
            <div className="bg-darkblue"> 
            <img className='about-ovpr-image full-width' src='/uploads/ovrp.png' alt='OVRP' />
            <div className='about-ovpr-info text-left text-white p-a-3'>
               <h6 className="p-b-1">Research is integral to Hamad Bin Khalifa University (HBKU)'s mission to help build human capacity in Qatar. It thrives across HBKU six colleges and  three national research institutes to advance science and technology across various disciplines and to find solutions for many local and global challenges.</h6>
                <h6>The Office of the Vice President for Research (OVPR) is responsible for developing and growing research initiatives and building an innovation pipeline to transform research into value creation in support of HBKU’s overall goals. </h6>
                <div className={OvprSupportShow ? "show" : "d-none"}>
                <h6>The VPR provides its support through:</h6>
                <ul>
                  <li>Developing and managing  HBKU’s research governance system</li>
                  <li>Developing the university’s overall research strategy</li>
                  <li>Fostering cross-divisional collaborations</li>
                  <li>Promoting and supporting local and global collaborations</li>
                  <li>Promoting university research to the local and global community</li>
                  <li>Providing seed funding to further support high-impact research</li>
                  <li>Managing  pre-award and post-awards for sponsored grants</li>
                  <li>Identifying and facilitating local and international funding opportunities for researchers</li>
                  <li>Coordinating between the colleges and the research institutes to optimize the use of the university’s resources while enhancing research impact</li>
                  <li>Providing research proposal preparation support </li>
                  </ul>
                  </div>
                <div className="text-left"><button className="action-white m-t-3 m-b-1"
                onClick={() => {
                  setOvprSupportShow(!OvprSupportShow);
                }}
                > {OvprSupportShow ? "Read Less" : "Read More"} <i className={OvprSupportShow ? "far fa-minus-square" : "far fa-plus-square"} style={{fontSize:' 18px', paddingLeft: '5px', fontWeight: 'normal'}}></i></button></div>
                </div>
              </div>
                
          </div>
        </section>
      <WelecomeQuoteInline
        itemId='vpr-office'
        title='Message from OVPR'
       // url='https://hbku-api.lndo.site/api/research/caa14df2-2019-4955-a8fc-dbfd1a4b18f7/field_official_message?resourceVersion=id%3A4'
      />
      <Footer {...footerEn} />
    </>
  )
}

export default VPROffice
