import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import { footerEn } from '../data/footer';
import { navigationTop, navigationButtom } from '../params';

const navigationIntern = [];




const DetailPage = (props) => {
const [detail, setDetail] = useState(null);
  const { section_type, id } = useParams(); // Extract parameters from the URL

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  console.log(detail);
  
  useEffect(() => {
    // Fetch data from the API
    const fetchData = async () => {
      try {
        const response = await fetch('https://www.hbku.edu.qa/en/api/hbku-pure.json');

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const json = await response.json();
        const editorGuideData = json[0].editor_guide_data || [];
        const sectionData = editorGuideData.find(
          (prod) => prod.section_type === section_type && prod.id === id
        );

        if (!sectionData) {
          throw new Error('No data found matching the given section_type and id');
        }
       

        setData(sectionData);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [section_type, id]);
  let breadcrumb_ttile =  section_type.replace("_", " ");
  let result = breadcrumb_ttile.charAt(0).toUpperCase() + breadcrumb_ttile.slice(1);
      // Default breadcrumb links

const defaultCrumb = [
    { link: '/', text: 'Home' },
    { link: '/research/'+section_type, text: result },
    { link: '/research/'+section_type, text: data ? data.editor_guide_title : '...' },
  ];

  // Set header data dynamically
  const headerData = {
    crumb: defaultCrumb,
    navigationButtom: navigationButtom,
    navigationTop: navigationTop,
    applicationLinks: [{ text: 'Apply now', class: 'bordred', link: '/header/applynow' }],
    title: data ? data.editor_guide_title : 'Loading...',
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div>
      <Navigation
        navigationIntern={navigationIntern}
        {...headerData}
        updateLang={props.updateLang}
      />

        <section className=' m-0 custom-research'>
          <div className='container'>
          <h2 className="mb-3">{data.editor_guide_title}</h2>
                {data && data.detail_page_content ? (
                data.detail_page_content.map((section, index) => (
                  
                    <div  key={index} id={`section-${index}`} // Unique dynamic ID for each section
                        className="section-container py-3 py-lg-5" // Add any additional class you need
                        >
              
                        {index !== 0 && <h3 className="mb-3">{section.section_title}</h3>}    {/* for skip first title */}
                   
                        <div
                        dangerouslySetInnerHTML={{ __html: section.section_body }}
                        className="section-description"
                        />
                    </div>
                ))
                ) : (
                data && <p>{data.editor_guide_link}</p>
                )}
          </div>
        </section>

        

      <Footer {...footerEn} />
    </div>
  );
};

export default DetailPage;