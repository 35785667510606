import '../assets/scss/Loader.scss'

function Loader() {
  return (
    <section className='loader py-5 text-center'>
      <div className='container py-5 text-center'>
        {/* <svg viewBox='0 0 36.270001 39.923173' height='100'>
          <title id='title6'>hbku</title>
          <polygon
            className='cls-1'
            points='90.22,0.05 81.4,8.86 90.22,17.68 '
            id='polygon8'
            transform='translate(-72.58,-0.05)'
          />
          <polygon
            className='cls-2'
            points='81.4,8.86 72.58,0.05 72.58,17.68 '
            id='polygon10'
            transform='translate(-72.58,-0.05)'
          />
          <polygon
            className='cls-1'
            points='100.03,8.86 108.85,0.05 91.21,0.05 '
            id='polygon12'
            transform='translate(-72.58,-0.05)'
          />
          <polygon
            className='cls-2'
            points='100.03,8.86 91.21,0.05 91.21,17.68 '
            id='polygon14'
            transform='translate(-72.58,-0.05)'
          />
          <polygon
            className='cls-2'
            points='108.85,18.68 100.03,27.49 108.85,36.31 '
            id='polygon16'
            transform='translate(-72.58,-0.05)'
          />
          <polygon
            className='cls-1'
            points='108.85,36.31 100.03,27.49 91.21,36.31 '
            id='polygon18'
            transform='translate(-72.58,-0.05)'
          />
          <polygon
            className='cls-2'
            points='100.03,27.49 91.21,18.68 91.21,36.31 '
            id='polygon20'
            transform='translate(-72.58,-0.05)'
          />
          <polygon
            className='cls-2'
            points='81.4,27.49 90.22,18.68 72.58,18.68 '
            id='polygon22'
            transform='translate(-72.58,-0.05)'
          />
          <polygon
            className='cls-1'
            points='81.4,27.49 72.58,18.68 72.58,36.31 '
            id='polygon24'
            transform='translate(-72.58,-0.05)'
          />
          <polygon
            className='cls-3'
            points='91.21,17.68 108.85,17.68 100.03,8.86 98.7,10.2 104.3,15.8 93.1,15.8 '
            id='polygon26'
            transform='translate(-72.58,-0.05)'
          />
          <polygon
            className='cls-3'
            points='72.58,17.68 90.22,17.68 81.4,8.86 80.07,10.2 85.67,15.8 74.47,15.8 '
            id='polygon28'
            transform='translate(-72.58,-0.05)'
          />
          <polygon
            className='cls-3'
            points='72.58,36.31 90.22,36.31 81.4,27.49 80.07,28.82 85.67,34.42 74.47,34.42 '
            id='polygon30'
            transform='translate(-72.58,-0.05)'
          />
          <polygon
            className='cls-3'
            points='91.21,36.31 108.85,36.31 106.96,34.42 93.1,34.42 '
            id='polygon32'
            transform='translate(-72.58,-0.05)'
          />
          <rect
            className='cls-3'
            x='-8.8424253'
            y='45.751015'
            width='1.88'
            height='1.88'
            transform='rotate(-45.02)'
            id='rect34'
          />
          <rect
            className='cls-3'
            x='-9.9576817'
            y='28.840523'
            width='1.89'
            height='1.88'
            transform='rotate(-45)'
            id='rect36'
          />
          <rect
            className='cls-3'
            x='4.3438125'
            y='32.585239'
            width='1.88'
            height='1.88'
            transform='rotate(-45)'
            id='rect38'
          />
        </svg> */}
        <figure>
          <img src="/uploads/preloader.gif" alt="" title="" className="img-responsive" />
        </figure>
      </div>
    </section>
  )
}

export default Loader

/*

 <svg
       id="Layer_1"
       data-name="Layer 1"
       
       version="1.1"
       sodipodi:docname="logo_no_text_color.svg"
       inkscape:version="1.0.1 (3bc2e813f5, 2020-09-07)"
     ">
   
   
      <defs
         id="defs4">
    
      </defs>


      
    
    </svg>
*/
