import React from 'react';
import '../../assets/scss/CoreLabsAboutUs.scss';

const AboutUs = ({about}) => {
  
  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: about?.body_1?.value }} />
    </>
  );
};

export default AboutUs;
