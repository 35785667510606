
import BannerVideoPlayer from '../BannerVideoPlayer'
function BannerHero(props) {
  return (
    <>
     <section dir={props.lang === 'ar' ? 'rtl' : 'lrt'} className='banner-hero m-a-0'>
     
        <BannerVideoPlayer 
        btnTransparent={props.btnTransparent} 
        poster={props.poster} 
        src={props.src}
        BannerTitle={props.title}
        BannerDes={props.description}
        
        id="myVideoPlay" 
        className="section-dynamic"/>
        
		
		</section>
    </>
  )
}

export default BannerHero;
