import React from 'react';

const ContactUs = ({contact}) => {

  return (
    <>
     <div dangerouslySetInnerHTML={{ __html: contact?.body_1?.value }} />
    </>
  );
};

export default ContactUs;
