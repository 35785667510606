import { Link } from 'react-router-dom'
import Navigation from '../components/Navigation'
import Footer from '../components/Footer'
import { footerEn /* footerAr*/ } from '../data/footer'
import { navigationTop, navigationButtom } from '../params'

import '../assets/scss/VprOffice.scss'

const navigationIntern = []

const crumb = [
  { link: '/', text: 'Home' },
  { link: '/404', text: 'Page not found' }
]

function NotFoundPage(props) {
  document.title = 'Page not Found - HBKU'
  const headerData = {
    title: 'Page not found.',
    crumb: crumb,
    navigationButtom: navigationButtom,
    navigationTop: navigationTop,
    applicationLinks: [{ text: 'Apply now', class: 'bordred', link: '/header/applynow' }],
  }
  return (
    <>
      <Navigation
        navigationIntern={navigationIntern}
        {...headerData}
        updateLang={props.updateLang}
      />
      
      <section id='vpr-office-bio' className="m-wrap" >
        <div className='container'>
          <div className='row'>
           <p><Link to="/">Go back to Research Home.</Link></p>
          </div>
        </div>
      </section>
      <Footer {...footerEn} />
    </>
  )
}
export default NotFoundPage;