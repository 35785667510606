import Navigation from '../components/Navigation'
// import VideoPlayer from '../components/VideoPlayer'
import Articles from '../components/Articles'
import Testimonials from '../components/Quotes/Testimonials'
// import UpcomingBlock from '../components/UpcomingBlock'
import Footer from '../components/Footer'
import { footerEn } from '../data/footer'
import { navigationTop, navigationButtom } from '../params'
const navigationIntern = [
  { text: 'Colleges', hash: '/research/colleges', isActive: false },
  { text: 'Research Institutes', hash: '/research/institutes', isActive: true},
  { text: 'Research Highlights', hash: '/projects', isActive: false},
]
const articledata = [
  {
    image: '/uploads/qbri.png',
    number: 1,
    hash: 'QBRI',
    title: 'Qatar Biomedical Research Institute',
    subtitle:
      'Qatar’s national research institute focuses on biomedical research striving to transform healthcare through translational research, innovation in the prevention, diagnosis, as well as treatment of high prevalence diseases. The ultimate goal is to achieve personalized medicine. QBRI applies integrative and multidisciplinary approaches to provide novel insights into the molecular basis and to develop novel biomarkers for breast cancer; diabetes; and neurological disorders such as autism spectrum disorder (ASD). In doing so, QBRI helps to improve the effectiveness of healthcare and quality of life, particularly for populations in Qatar and the region, while contributing to research of global relevance.',
    paragraphs: [
      '',
    ],
    more: 'More about QBRI',
    link: 'https://www.hbku.edu.qa/en/qbri/about',
    research: 'Research at QBRI',
    research_link: '',
  },
  {
    image: '/uploads/qeeri.png',
    number: 2,
    hash: 'QEERI',
    title: 'Qatar Environment and Energy Research Institute',
    subtitle:
      'Qatar’s national research institute focuses on water, energy, and the environment in desert and arid regions, aiming to anticipate and solve critical challenges facing Qatar and the world. QEERI conducts market-driven research and fulfills national advisory and educational roles. Alongside proposing strategies and contributing to policy-making, QEERI connects academia with the industry and market, providing them with the necessary fundamental and applied research inputs.',
    paragraphs: [
      '',
    ],
    more: 'More about QEERI',
    link: 'https://www.hbku.edu.qa/en/qeeri/about',
    research: 'Research at QEERI',
    research_link: '',
  },
  {
    image: '/uploads/qcri.png',
    number: 3,
    hash: 'QCRI',
    title: 'Qatar Computing Research Institute ',
    subtitle:
      'Qatar’s national research institute is focused on tackling large-scale computing challenges that address national priorities for growth and development. QCRI’s mission is to help build Qatar’s innovation and technology capacity. The institute conducts world-class multidisciplinary computing research that is relevant to the needs of Qatar, the wider Arab region, and the world, in four focus areas:  Arabic Language Technologies, Social Computing, Data Analytics, and Cyber Security.',
    paragraphs: [
      '',
    ],
    more: 'More about QCRI',
    link: 'https://www.hbku.edu.qa/en/qcri/about',
    research: 'Research at QCRI',
    research_link: '',
  },
]



const testimonials = {
  title: 'Quotes and Testimonials',
  items: [
    {
      image: '/uploads/Dr_Richard_O_Kennedy_VP.png',
      quote: "It is this inter-disciplinarity and collaborative spirit that HBKU nurtures as part of its ambitious roadmap which aims to identify new opportunities to contribute to research, regionally and nationally, working in synergy with our partners. Because research and knowledge is needed to drive the progress of the country and National Vision 2030, we continuously strive for real-world impact in our research at HBKU.",
      writer: {
        avatar: '/uploads/Dr_Richard_O_Kennedy_VP.png',
        name: 'Dr. Richard O’Kennedy',
        position:
          'VP FOR RESEARCH AT HBKU, AND THE VP FOR RESEARCH, DEVELOPMENT AND INNOVATION (RDI) AT QF',
      },
    }
  ],
}

const crumb = [
  { link: '//', text: 'Home' },
  { link: '/', text: 'Research' },
  { link: '/research/institutes', text: 'Research Institutes' },
]

function InstituteView(props) {
  document.title = 'Research Institutes - HBKU'
  const poster = '/uploads/research_institute.png'
  //const src = '/uploads/welcome.mp4'
  const headerData = {
    title: 'Research at HBKU',
    crumb: crumb,
    navigationButtom: navigationButtom,
    navigationTop: navigationTop,
    applicationLinks: [{ text: 'Apply now', class: 'bordred', link: '/header/applynow' }],
  }
  
  return (
    <>
      <Navigation
        navigationIntern={navigationIntern}
        {...headerData}
        updateLang={props.updateLang}
      />

      <section className='p-section' id={'Institutes'}>
        <div className='container'>
          <h2 className='text-hbku-primary'>Research Institutes </h2>
          <div className='row mt-5'>
            <div className='col-12 col-md-6  padding-right-40'>
              <p className='display2a'>
              Research is at the cornerstone of Hamad Bin Khalifa University (HBKU)’s commitment to building human capacity through interdisciplinary education and innovation that supports Qatar’s journey towards sustainable growth.
              </p>
              <p className='display2a'>
              The university boasts three national research institutes, Qatar Environment and Energy Research Institute (QEERI), Qatar Biomedical Research Institute (QBRI), and Qatar Computing Research Institute (QCRI), which were established in 2011  in line with Qatar National Vision 2030 to seek novel solutions to grand challenges facing Qatar and the region.
              </p>
              <div className="overview">
              <ul className="list-item p-b-2">
                <li><a href="#QBRI"  rel="noreferrer">Qatar Biomedical Research Institute</a></li>
                <li><a href="#QEERI"  rel="noreferrer">Qatar Environment and Energy Research Institute</a></li>
                <li><a href="#QCRI"  rel="noreferrer">Qatar Computing Research Institute </a></li>
              </ul>
              </div>
            </div>
            <div className='col-12 col-md-6  padding-left-40'>
            <img className='about-ovpr-image full-width' src={poster} alt='Research Institutes' />
              {/* <VideoPlayer btnTransparent poster={poster} src={src} /> */}
            </div>
          </div>
        </div>
      </section>
      <Articles inverse data={articledata} />
     
      {/* <UpcomingBlock
      title={'Research Themes'}
      desc={'It will launch in next phase -2'} 
      isPhase2={true}
      />

      <UpcomingBlock
      title={'Research Highlights'}
      desc={'Content will come after discussion.'}
      
      /> */}
      {/* <Testimonials {...testimonials} /> */}
      <Footer {...footerEn} />
    </>
  )
}

export default InstituteView
/*
import Footer from '../components/Footer'
import NewsBanner from '../components/Banners/NewsBanner'
import GridCarousel from '../components/Caroussels/GridCarousel'
import FactsCaroussel from '../components/Caroussels/FactsCaroussel'
import CardHero from '../components/Cards/CardHero'
import WelecomeQuote from '../components/Quotes/WelecomeQuote'
import MoreBanner from '../components/Banners/MoreBanner'

import { footerEn /* footerAr* } from '../data/footer'
import { HeaderBanner } from '../data/banner'
import { grid } from './carouselData'
import axios from 'axios'
import { Component } from 'react'


const moreBanner = {
  title: 'More about our Research',
  description:
    'Pellentesque at lacus maximus, tincidunt nulla a, finibus turpis. Duis vitae malesuada ante. Sed in accumsan lacus. Ut ac elit eget leo tempus imperdiet at sit amet justo.',
  links: [
    [
      'IP & Commercilization',
      'Partnerships & Innovation',
      'Career opportunities',
      'Academic Research',
    ].map((e, k) => ({ text: e, link: '/banners/link1-' + k })),
    [
      'Sponsored Research',
      'Research Facilities',
      'Research Reports',
      'Research Projects',
    ].map((e, k) => ({ text: e, link: '/banners/link2-' + k })),
  ],
}
class HomePage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: '',
      body: '',
      welcomeMessage: null,
    }
  }

  componentDidMount() {
    axios
      .get('http://api.boufaied.com/api/research/caa14df2-2019-4955-a8fc-dbfd1a4b18f7')
      .then(res => {
        const attributes = res.data.data.attributes
        const relationships = res.data.data.relationships
        // console.log(attributes)
        let state = {
          title: attributes.title,
          body: attributes.body.processed,
          welcomeMessage: relationships.field_official_message.links.related.href,
          focusAreas: null,

          // field_research_colleges: {},
          // field_research_institutes: {},
        }

        this.setState(state, () => {
          axios
            .get(relationships.field_thematic_focus_areas.links.related.href)
            .then(res => this.setState({ focusAreas: res.data }))
            .catch(err => console.log(err))
        })
      })
      .catch(err => console.log(err))
  }

  render() {
    
    const data = {
      cardHeroData: {
        description:
          'Our research is divided into focus areas dedicated to solving critical challenges facing Qatar and the world.',
        title: 'Thematic Focus Areas',
        data: [
          'Artificial Intelligence',
          'Sustainability',
          'Precision Medicine',
          'Disruptive Education',
          'Social Progress',
        ].map(e => ({
          title: e,
          photo: 'https://picsum.photos/300/200',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet ullamcorper sem, eget consectetur',
          actionLink: '/cards/' + e.toLowerCase().replaceAll(' ', '-'),
          actionText: 'Learn more',
        })),
      },
    }

    const factCarousel = {
      title: 'Upcoming Research Activities',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce dolor sapien, sodales vehicula leo et, elementum vulputate sapien. Nulla lacinia vehicula dapibus.',
      items: [
        {
          number: '75+',
          name: 'Core facilities',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
          linkText: 'View all core facilities',
          link: '/caroussels',
        },
        {
          number: '1520+',
          name: 'Publications',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
          linkText: 'View all core facilities',
          link: '/caroussels',
        },
        {
          number: '660+',
          name: 'Patents',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
          linkText: 'View all core facilities',
          link: '/caroussels',
        },
        {
          number: '45587+',
          name: 'Lorem ipsum dolor',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
          linkText: 'View all core facilities',
          link: '/caroussels',
        },
        {
          number: '15+',
          name: 'consectetur adipiscing',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
          linkText: 'View all core facilities',
          link: '/caroussels',
        },
        {
          number: '15+',
          name: 'consectetur adipiscing',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
          linkText: 'View all core facilities',
          link: '/caroussels',
        },
        {
          number: '15+',
          name: 'consectetur adipiscing',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
          linkText: 'View all core facilities',
          link: '/caroussels',
        },
      ].map(e => ({ ...e, image: '/uploads/image2.jpg' })),
    }

    return (
      <>
        <section>
          <div
            className='container py-5 text-center display1'
            dangerouslySetInnerHTML={{ __html: this.state.body }}></div>
        </section>
        <WelecomeQuote title='Welcome Message' url={this.state.welcomeMessage} />
        <CardHero {...data.cardHeroData} />
        <FactsCaroussel {...factCarousel} />
        <GridCarousel
          {...grid}
          title='Research Highlights'
          description='Latest Happening frm HBKU'
        />
        <NewsBanner {...HeaderBanner} />
        <MoreBanner {...moreBanner} />
        <Footer {...footerEn} />
      </>
    )
  }
}

export default HomePage


*/
