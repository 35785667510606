import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'
import '../../assets/scss/ImageSlider.scss'
import { Gallery, Item } from 'react-photoswipe-gallery'

function ModalGallery(props) {

  if (!props.Items || props.Items.length === 0) return <></>
  const Items = props.Items
  return (
    <div className="card-block">
                        <div className="project-summary project-gallery project-gallery-lightpopup">
                           <h3>Photos, schematics, graphs.....etc.</h3>
                           <Gallery >
    {Items.map((item, k) => 
          <Item
          
          original={item.imgUrl}
          thumbnail={item.imgUrl}
          width="1024"
          height="768"
          key={k}
        >
          {({ ref, open }) => (
            <img alt="" ref={ref} onClick={open} src={item.imgUrl} />
          )}
        </Item>
            )}
  </Gallery>
                        </div>
                    </div>

  )
      }


export default ModalGallery;