export const footerEn = {
  lang: 'en',
  footerText: 'Hamad Bin Khalifa University Education City Doha',
  footermenues: [
    {
      title: 'About',
      links: [
        { link: 'https://www.hbku.edu.qa/en/about-hbku/hbku-leadership', text: 'HBKU Leadership' },
        { link: 'https://www.hbku.edu.qa/en/careers', text: 'Careers' },
        { link: 'https://www.hbku.edu.qa/en/people', text: 'People' },
        { link: 'https://www.hbku.edu.qa/en/about-hbku/departments', text: 'Administrative Departments' },
        // { link: '/', text: 'Centers' },
      ],
    },
    {
      title: 'Academics',
      links: [
        { link: 'https://www.hbku.edu.qa/en/academics', text: 'Colleges' },
        // { link: '', text: 'Student Resources' },
        { link: 'https://www.hbku.edu.qa/en/eec', text: 'Executive Education' },
        { link: 'https://www.hbku.edu.qa/en/admissions', text: 'Admissions' },
        { link: 'https://www.hbku.edu.qa/en/hbku-library', text: 'HBKU Library' },
      ],
    },
    {
      title: 'Research',
      links: [
        // { link: 'https://www.hbku.edu.qa/en/research ', text: 'Research at HBKU' },
        { link: 'https://www.hbku.edu.qa/en/research/sro ', text: 'Sponsored Research Office' },
        { link: '/research/facilities-services', text: 'Facilities' },
        { link: '/research/funding', text: 'Funding' },
        { link: '/research/office-vpr', text: 'OVPR' },
      ],
    },
    {
      title: 'Life',
      links: [
        { link: 'https://www.hbku.edu.qa/en/life-hbku', text: 'Overview' },
        { link: 'https://www.hbku.edu.qa/en/life-hbku/housing-education-City', text: 'Housing at Education City' },
        { link: 'https://next.qf.org.qa/education/student-financial-services', text: 'Financial Aid & Scholarship' },
        { link: 'https://www.hbku.edu.qa/en/academic-calendar/2023-2024#/year/29870', text: 'Academic Calendar' },
        // { link: '/', text: 'Careers' },
      ],
    },
  ],
  copyright: 'All rights reserved to Hamad Bin Khalifa University.',
  footerPages: [
    // { link: '/', text: 'Emergency information' },
    // { link: '/', text: 'Disclaimer and copyright' },
    // { link: '/', text: 'Accessibility' },
    { link: 'https://www.hbku.edu.qa/en/legal-information', text: 'Cookies Policy ' },
    { link: 'https://www.hbku.edu.qa/en/privacy-policy', text: 'Privacy' },
  ],
}
export const footerAr = {
  lang: 'ar',
  footerText: 'جامعة حمد بن خليفة بالمدينة التعليمية بالدوحة',

  footermenues: [
    {
      title: 'الدراسة في جامعة حمد بن خليفة',
      links: [
        { link: '/', text: 'رسوم القبول' },
        { link: '/', text: 'الطلاب الدوليين' },
        { link: '/', text: 'وظائف في جامعة حمد بن خليفة' },
        { link: '/', text: 'اتصل بنا' },
        { link: '/', text: 'تقنية' },
      ],
    },
    {
      title: 'الدراسة في جامعة حمد بن خليفة',
      links: [
        { link: '/', text: 'رسوم القبول' },
        { link: '/', text: 'الطلاب الدوليين' },
        { link: '/', text: 'وظائف في جامعة حمد بن خليفة' },
        { link: '/', text: 'اتصل بنا' },
        { link: '/', text: 'تقنية' },
      ],
    },
    {
      title: 'الدراسة في جامعة حمد بن خليفة',
      links: [
        { link: '/', text: 'رسوم القبول' },
        { link: '/', text: 'الطلاب الدوليين' },
        { link: '/', text: 'وظائف في جامعة حمد بن خليفة' },
        { link: '/', text: 'اتصل بنا' },
        { link: '/', text: 'تقنية' },
      ],
    },
  ],
  copyright: 'جميع الحقوق محفوظة لجامعة حمد بن خليفة.',
  footerPages: [
    { link: '/', text: 'معلومات الطوارئ' },
    { link: '/', text: 'إخلاء المسؤولية وحقوق التأليف والنشر' },
    { link: '/', text: 'إمكانية الوصول' },
    { link: '/', text: 'خصوصية' },
  ],
}
