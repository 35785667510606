import React, { useEffect, useState } from 'react';
import Footer from '../components/Footer';
import Navigation from '../components/Navigation';
import { footerEn } from '../data/footer';
import { navigationButtom, navigationTop } from '../params';
import { Link } from 'react-router-dom';

import '../assets/scss/Rms.scss';

const navigationIntern = [
  // { text: 'Funding', hash: '#funding-list' },
  // { text: 'Funding', hash: '#funding-news', isActive: true },
  // { text: 'Filter News', hash: '#filter-news' },
];

const crumb = [
  { link: '//', text: 'Home' },
  { link: '/', text: 'Research' },
  { link: '/research/researcherguide', text: 'Researcher Guide' },
];

function ResearcherGuide(props) {
  const [researcherguideData, setResearcherGuideData] = useState([]);
  const [researcherPageTitle, setResearcherPageTitle] = useState('');
  const [researcherPageDescription, setResearcherPageDescription] = useState('');
  const [guideData, setGuideData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    document.title = 'Researcher Guide';

    // Fetch data from the API
    fetch('https://www.hbku.edu.qa/en/api/hbku-pure.json')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        // Log data to see structure


        // Filter for researcher_guide section
        if (data && data.length > 0) {
          const researcherGuide = data[0]?.editor_guide_data?.filter(
            item => item.section_type === 'researcher_guide'
          );

          setResearcherGuideData(researcherGuide || []);
          setResearcherPageTitle(data[0]?.researcher_page_title?.value || 'Researcher Guide');
          setResearcherPageDescription(data[0]?.researcher_page_description?.safe_value || 'Welcome to the Researcher Guide.');
        } else {
          setError('Data not found.');
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setError('Failed to load data.');
      });
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  const headerData = {
    title: 'Research at HBKU',
    crumb: crumb,
    navigationButtom: navigationButtom,
    navigationTop: navigationTop,
    applicationLinks: [{ text: 'Apply now', class: 'bordred', link: '/header/applynow' }],
  };

  return (
    <>
      <Navigation
        navigationIntern={navigationIntern}
        {...headerData}
        updateLang={props.updateLang}
      />

      <section className=" m-0 custom-research">
        <div className="container">
          {/* Dynamically render the researcher page title */}
          <h2 className="mb-3">{researcherPageTitle}</h2>

          {/* Dynamically render the researcher page description */}
          
          <div dangerouslySetInnerHTML ={{ __html: researcherPageDescription }} />

          <ul className="row py-4 gx-3 gx-lg-5 guide-list">
            {researcherguideData.length > 0 ? (
              researcherguideData.map((item, index) => (
                <li className="col-md-6" key={index}>
                  {/* <a href="#">{item.editor_guide_title}</a> */}
          
                  <Link to={`/research/${item.section_type}/${item.id}`}>{item.editor_guide_title}</Link>
                </li>
              ))
            ) : (
              <li>No guide data available.</li>
            )}
          </ul>
        </div>
      </section>

      <Footer {...footerEn} />
    </>
  );
}

export default ResearcherGuide;