import React, { useState } from 'react';

const Output = ({ output, pastEvents }) => {
    const [intern, setIntern] = useState('Past Events');
    const [activeYear, setActiveYear] = useState(output[1]?.all_year[0]);
    
    const isPastEvents = intern === 'Past Events';
    const filteredOutput = output.filter(item => item?.output_year === activeYear);

    return (
        <>
            <div className='header-intern text-wrap'>
                <div className='container'>
                    <a className={`m-b-1 ${isPastEvents ? 'active' : ''}`} style={{ cursor: 'pointer' }} onClick={() => setIntern('Past Events')}>
                        Past Events 
                    </a>
                    <a className={`m-b-1 ${!isPastEvents ? 'active' : ''}`} style={{ cursor: 'pointer' }} onClick={() => setIntern('Publications (Articles In Peer-Reviewed Journals)')}>
                        Publications (Articles In Peer-Reviewed Journals)
                    </a>
                </div>
            </div>
            <section className='m-wrap'>
                {isPastEvents ? (
                    <div dangerouslySetInnerHTML={{ __html: pastEvents && pastEvents[0]?.body_1?.value }} />
                ) : (
                    <div className='container'>
                        <div className='d-flex publications__head m-b-1'>
                            {output[1]?.all_year?.map((menuItem, index) => (
                                <div className={`full-width yearly__data ${menuItem === activeYear ? 'active' : ''}`} key={index} onClick={() => setActiveYear(menuItem)}>
                                    {menuItem && <h6>{menuItem}</h6>}
                                </div>
                            ))}
                        </div>
                        {filteredOutput.map((publication, index) => (
                            <div className='publications__content' key={index}>
                                <p className='p-y-1'><a href={publication?.technique_pdf_link?.url}>{publication?.technique_body}</a></p> 
                            </div>
                        ))}
                    </div>
                )}
            </section>
        </>
    );
};

export default Output;
