// import { Link } from 'react-router-dom'
// import VideoPlayer from '../components/VideoPlayer'
import Navigation from '../components/Navigation'
import Articles from '../components/Articles'
import Testimonials from '../components/Quotes/Testimonials'
import Footer from '../components/Footer'
import { footerEn } from '../data/footer'
import { navigationTop, navigationButtom } from '../params'
const navigationIntern = [
  { text: 'Colleges', hash: '/research/colleges', isActive: true},
  { text: 'Research Institutes', hash: '/research/institutes', isActive: false},
  { text: 'Research Highlights', hash: '/projects', isActive: false},
]
const articledata = [
  {
    image: '/uploads/cis.png',
    number: 1,
    hash: 'CIS',
    title: 'College of Islamic Studies',
    subtitle:
      "The College of Islamic Studies (CIS) strives to advance a more nuanced understanding of Islam and its social dimensions in a rapidly changing world.",
    paragraphs: [
      "To complement this, the research ecosystem at the college is designed to stimulate learning and collaborative research through its multifaceted infrastructure, which comprises the research division, research clusters, research centers, and value-driven projects. Altogether, the research infrastructure facilitates the promotion of interdisciplinary research agendas and new contributions to the world of global Islamic scholarship. It streamlines the diverse research activities of CIS towards its key research areas of excellence and its research clusters while emulating Qatar Foundation's thematic focus areas into its research strategy and implementation.",
    ],
    more: 'More about CIS',
    link: 'https://www.hbku.edu.qa/en/cis/about',
    research: 'Research at CIS',
    research_link: '',
  },
  {
    image: '/uploads/chss.png',
    number: 2,
    hash: 'CHSS',
    title: 'College of Humanities and Social Sciences',
    subtitle:
      "The College of Humanities and Social Sciences (CHSS) builds national capacity in fields of global importance. The research at the college focuses on the fields of Middle Eastern Studies, as well as Translation Studies through its Translation and Interpreting Institute (TII). ",
    paragraphs: [
      "Research at CHSS mainly focuses on four areas: Diversity, Inclusion and Accessibility, Gender Equality and Empowerment, Digital Humanities and Arabic Language, and Multilingual Terminology Management. CHSS is a driver for social justice, enriching local and global communities with transformative knowledge, innovative research, as well as opportunities for collaboration.",
    ],
    more: 'More about CHSS',
    link: 'https://www.hbku.edu.qa/en/chss/about',
    research: 'Research at CHSS',
    research_link: '',
  },
  {
    image: '/uploads/cse.png',
    number: 3,
    hash: 'CSE',
    title: 'College of Science and Engineering',
    subtitle:
      "The College of Science and Engineering (CSE) aspires to be a world-class multidisciplinary college with a significant positive impact on Qatar, the region, and globally, in science, engineering, and technology.",
    paragraphs: [
      "CSE focuses on advancing knowledge and nurturing technically grounded leaders and innovators to serve societal needs. The college’s research is conducted across its academic divisions; the Division of Sustainable Development, the Division of Information and Computing, and the Division of Engineering Management and Decision Sciences, which together form a unique integrated multidisciplinary education and research environment.",
    ],
    more: 'More about CSE',
    link: 'https://www.hbku.edu.qa/en/cse/about',
    research: 'Research at CSE',
    research_link: '',
  },
  {
    image: '/uploads/cl.png',
    number: 4,
    hash: 'CL',
    title: 'College of Law',
    subtitle:
      "The College of Law addresses today’s legal challenges with a future-oriented view and awareness of rapid technological developments.",
    paragraphs: [
      "The college uses an innovative, comparative approach, relying on three major legal disciplines - common law, civil law, and Shari’a as the backdrop. The College of Law promotes an understanding of the rule of law in today’s dynamic world. With an international reputation for quality and innovation in teaching and research, the college nurtures and develops skilled leaders to manage multi-faceted relationships across diverse legal systems.",
    ],
    more: 'More about College of Law',
    link: 'https://www.hbku.edu.qa/en/cl/about',
    research: 'Research at CL',
    research_link: '',
  },
  {
    image: '/uploads/chls.png',
    number: 5,
    hash: 'CHLS',
    title: 'College of Health and Life Sciences',
    subtitle:
      "The College of Health and Life Sciences (CHLS) embodies a multidisciplinary learning approach to research and aims to become a dedicated hub of knowledge sharing in the area of health and life sciences.",
    paragraphs: [
      "The research at the college emphasizes on fields of biomedical sciences, genomics, precision medicine, and exercise science. The creation of multidisciplinary teams is a major focus, allowing the college to attract the necessary resources to maintain and build the technologies required to support its research goals.",
    ],
    more: 'More about CHLS',
    link: 'https://www.hbku.edu.qa/en/chls/about',
    research: 'Research at CHLS',
    research_link: '',
  },
  {
    image: '/uploads/cpp.png',
    number: 6,
    hash: 'CPP',
    title: 'College of Public Policy',
    subtitle:
      "The College of Public Policy (CPP) is a global hub for international public policy education and research based in Qatar.",
    paragraphs: [
      "CPP offers a distinctive combination of interdisciplinarity, strong ethical foundations, entrepreneurship in public management, and innovation in policy making and design and offers world-class academic and professional training in public policy analysis, design, implementation, evaluation, and management; as well as specializations in social policy, and in energy and the environment. Together, the distinctive combination reflects the priority that the university places on supporting and contributing to effective policy developments and implementation for the Qatar National Vision 2030.",
    ],
    more: 'More about CPP',
    link: 'https://www.hbku.edu.qa/en/cpp/about',
    research: 'Research at CPP',
    research_link: '',
  },
]

const testimonials = {
  title: 'Quotes and Testimonials',
  items: [
    {
      image: '/uploads/Michael_0.jpeg',
      quote: "In the 11 years since we were established, the University has proven that it is the vanguard of research at the local, regional and global stage. As a result, HBKU has been able to attract talent, high profile industry partnerships and research grants that drive innovation. Our location in Education City has put us at the heart of an ecosystem that is vital for increasing the chances of creating impact out of university research.",
      writer: {
        avatar: '/uploads/Michael_0.jpeg',
        name: 'Dr. Michael J. Benedik',
        position:
          'PROVOST, HBKU',
      },
    }
  ],
}

const crumb = [
  { link: '/', text: 'Home' },
  { link: '/research', text: 'Research' },
  { link: '/research/colleges-and-commercialization', text: 'Colleges IP & Commercialization' },
]

function ResearchAtColleges(props) {
  document.title = 'Colleges IP & Commercialization - HBKU'
  const poster = '/uploads/Colleges.png'
  //const src = '/uploads/welcome.mp4'
  const headerData = {
    title: 'Research at HBKU',
    crumb: crumb,
    navigationButtom: navigationButtom,
    navigationTop: navigationTop,
    applicationLinks: [{ text: 'Apply now', class: 'bordred', link: '/header/applynow' }],
  }
  return (
    <>
      <Navigation
        navigationIntern={navigationIntern}
        {...headerData}
        updateLang={props.updateLang}
      />

      <section id='Colleges' className="overview">
        <div className='container'>
          <div className='row mt-5'>
            <div className='col-12 col-md-6 padding-right-40'>
              <h2 className='text-hbku-primary m-in-section'>Colleges</h2>
              <p className='display2a mb-5'>
              Hamad Bin Khalifa University (HBKU) was founded as a research-intensive university that acts as a catalyst for transformative change in Qatar and the region while having global impact. Across each of HBKU’s six colleges, students work under the expert guidance of faculty to conduct research that results in tangible solutions and innovative outcomes.
              </p>
              
              <ul className="list-item p-b-2">
                <li><a href="#CIS" >College of Islamic Studies</a></li>
                <li><a href="/research/colleges#CHSS" >College of Humanities and Social Sciences</a></li>
                <li><a href="/research/colleges#CSE" >College of Science and Engineering </a></li>
                <li><a href="/research/colleges#CL" >College of Law</a></li>
                <li><a href="/research/colleges#CHLS" >College of Health and Life Sciences</a></li>
                <li><a href="#CPP" >College of Public Policy</a></li>
              </ul>
            </div>
            <div className='col-12 col-md-6 padding-left-40'>
            <img className='about-ovpr-image full-width' src={poster} alt='Colleges' />
              {/* <VideoPlayer btnTransparent poster={poster} src={src} /> */}
            </div>
          </div>
        </div>
      </section>
      <Articles inverse data={articledata} />
      <Footer {...footerEn} />
    </>
  )
}

export default ResearchAtColleges
