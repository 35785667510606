import axios from 'axios'
import { useEffect } from 'react'
// import { Link } from 'react-router-dom'
// import Loader from '../Loader'
import '../../assets/scss/MoreBannerList.scss'

function MoreBannerLink(props) {
 // const [loading, setLoading] = useState(true)
 // const [title, setTitle] = useState('')
//  const [links, setlinks] = useState([])
 // const [description, setDescription] = useState('')
  const links = [
   [
    { text: 'Innovation Center', link: 'https://www.hbku.edu.qa/en/innovation-center' },
    { text: 'SRO Publications', link: 'https://www.hbku.edu.qa/en/research/sro/outcomes-filters' },
    
  ],
  [
    { text: 'SRO Projects', link: 'https://www.hbku.edu.qa/en/research/sro/projects-filter' },
    { text: 'Thematic Research Grant Program', link: 'https://www.hbku.edu.qa/en/thematic-research-grant-program' },
  ],
  [
    { text: 'Administrative Departments', link: 'https://www.hbku.edu.qa/en/about-hbku/departments' },
    { text: 'IC Workshops', link: 'https://www.hbku.edu.qa/en/innovation-center#workshops' }
  ],
  [
    { text: 'Executive Education Center', link: 'https://www.hbku.edu.qa/en/eec' }
  ],
  ]
  
  useEffect(() => {
    if (props.url !== null)
      axios
        .get(props.url)
        .then(res => {
          const description = document.createElement('div')
          const attributes = res.data.data.attributes
          description.innerHTML = attributes.field_description.value
         // setTitle(attributes.field_title)
        //  setLoading(false)
          //setDescription(description.innerText)
          //const links = [[], []]
          // attributes.field_links.map((e, k, arr) => {
          //   if (k < arr.length / 2) links[0].push({ link: e.uri, text: e.title })
          //   else links[1].push({ link: e.uri, text: e.title })
          //   return null
          // })
          
         // setlinks(links)
        })
        .catch(err => {
          console.log(err)
        })
  }, [props.url])

 

  return (
    <section dir={props.lang === 'ar' ? 'rtl' : 'ltr'}>
      <div className='container'>
        <div className='more-banner-list'>
          <h2>Quick Links</h2>
          <div className='banner-container'>
            {/* <div className='description'>
              <p><div>Pellentesque at lacus maximus, tincidunt nulla a, finibus turpis. Duis vitae 
malesuada ante. Sed in accumsan lacus. Ut ac elit eget leo tempus imperdiet at sit amet justo.</div></p>
              <form onSubmit={event => event.preventDefault()}>
                <input type='serach' placeholder='Keyword ...' />
                <button>Search</button>
              </form>
            </div> */}
            <div className='links'>
             
              {links.map((col, colkey) => (
                <ul key={colkey}>
                  {col.map((e, k) => (
                    <li key={k}>
                      <a href={e.link} target={'_blank'} rel="noreferrer">{e.text}</a>
                    </li>
                  ))}
                </ul>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MoreBannerLink
