import React, { Component } from 'react'

import '../assets/scss/FacilitiesBlock.scss';

class FacilitiesBlockList extends Component{
  state = {
    selected: false,
    showIcon: false,
  };

  handleToggle = () =>{

    this.setState(state => ({
      selected: !state.selected,
      showIcon: !state.showIcon,
    }))
  };
  render(props){
    const { data } = this.props;
    return (
              <div className="t-block_list slow-up-down">
              <div className="t-block__list--image">
                <figure className="ripple-effect overlay">
                  <span className="hbku-tags m-b-0">{data.hbkutag}</span>
                  <img src={data.avatar}
                    alt="" className="full-width" />
                    {/* <span className="tags_bookmark save-tags">{data.savetag} <i className="far fa-bookmark "></i></span> */}
                </figure>
              </div>
              <div className="t-block__list__info">
                <div className="t-block__list--title">
                  <h4 className="item--title">{data.title}
                  </h4>
                </div>
                <div className="t-block__list--content">
                  {data.description}
                </div>
                
                
                {/* <div className={this.state.selected ? "selected m-t-2" : "d-none"}>
                  <p>{data.showhideText}  </p>
                  </div> */}

                 <div className={this.state.selected ? "selected" : "d-none"} >
                  <p dangerouslySetInnerHTML={{__html: data.showhideText}} />
                  </div>


                  

                <div className="t-block__list--btn"><button onClick={this.handleToggle}
                className=" m-t-3 m-b-1"> {this.state.showIcon ? "Read Less" : "Read More"} <i className={this.state.showIcon ? "far fa-minus-square" : "far fa-plus-square"}></i></button></div>
              </div>
          </div>
        )
  }
}

export default FacilitiesBlockList