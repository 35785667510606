import React, { useEffect, useState } from 'react';

const Techniques = ({techniques, page}) => {

  const [subLab, setSubLab] = useState(page !== 'thin-film' ? 'Microscopy and Microanalysis Lab' :'Thin Film Deposition Lab');

  useEffect(() => {
      window.scrollTo(0, 0);
  }, [subLab]);

  return (
    <>
      { page === 'techniques' ?
      <div className='header-intern text-wrap'>
        <div className='container'>
          {Array.isArray(techniques) && techniques[0]?.all_sub_lab?.map((menuItem, k) => {
            return (
              <React.Fragment key={k}>
              {
              menuItem &&
              <a className={`m-b-1 cursor-pointer ${menuItem === subLab ? 'active' : ''}`} onClick={()=>{setSubLab(menuItem)}}>
                {menuItem} <i className={menuItem}></i>
              </a>
              }
              </React.Fragment>
            )
          })}
        </div>
      </div>
      :
        <div className='container'>
          {techniques[0]?.services_description?.value && <div  dangerouslySetInnerHTML={{ __html: techniques[0]?.services_description?.value}} />}
        </div>
      }
    
    <section className='m-wrap'>
      <div className='container'>
        <div className='news__block'>
          <ul>
            {Array.isArray(techniques) && techniques?.filter(item => item?.sub_lab === subLab)?.map((lab, index) =>{
              return (
                <li className="items techniques_card" key={index}>
                  <div className="mez thumb-block">
                    <figure>
                      <img src={lab?.techniques_image} alt="" title="" className="img-responsive full-width" />
                    </figure>
                    <div className="thumb-block__detail" style={{paddingBottom: 0}}>
                      <div className="thumb-block__wrap">
                        <h2>{lab?.title}</h2>
                      </div>
                      <div className="thumb-block-des">
                        <p className="technique_content">{lab?.technique_body}</p>
                        <div className='py-3'> 
                        <a href={lab?.technique_pdf_link?.url} target="_blank" rel="noopener noreferrer" className='l-btn read-more fw-bolder text-decoration-none' style={{color: '#0088CE', position: 'relative', paddingTop: 0}}>
                          Learn More <i className="ps-1 fas fa-arrow-right"></i>
                        </a>
                      </div>
                      </div>
                    </div>
                  </div>
                </li>
              )
            })
            
            }
          </ul>
        </div>
      </div>
    </section>
    </>
  )
}

export default Techniques;