import Footer from '../components/Footer'
import Navigation from '../components/Navigation'

import { footerEn } from '../data/footer'
import { navigationButtom, navigationTop } from '../params'

import '../assets/scss/Funding.scss'

const navigationIntern = [
  { text: 'Facts and Figures', hash: '#facts-and-figures', isActive: true },
  { text: 'Key Partnerships', hash: '#partnership' },
]

const crumb = [
  { link: '/', text: 'Home' },
  { link: '/research', text: 'Research' },
  { link: '/research/impact', text: 'Impact' },
]


function Impact(props) {
  document.title = 'Research Impact - HBKU'
  const headerData = {
    title: 'Research at HBKU',
    crumb: crumb,
    navigationButtom: navigationButtom,
    navigationTop: navigationTop,
    applicationLinks: [{ text: 'Apply now', class: 'bordred', link: '/header/applynow' }],
  }
  return (
    <>
      <Navigation
        navigationIntern={navigationIntern}
        {...headerData}
        updateLang={props.updateLang}
      />
      
      <section id='facts-and-figures' className="m-wrap funding-list">
        <div className='container'>
          <h2 className="text-hbku-primary p-b-3">Facts and Figures</h2>
          <div className='funding-list-info'>
              <ul>
                <li>
                  <img src="/uploads/icons/nd.svg" alt={'Publications'} />
                  <span>87</span>
                  <h4>Number of Invention Disclosures in AY21-22</h4>
                </li>
                <li>
                  <img src="/uploads/icons/np.svg" alt={'Patents'} />
                  <span>14</span>
                  <h4>Number of <br /> Patents AY21-22</h4>
                </li>
                <li>
                  <img src="/uploads/icons/hindex.svg " alt={'Invention disclosure'} />
                  <span>19.178</span>
                  <h4>Avg Institutional <br /> H-index</h4>
                </li>
                <li>
                  <img src="/uploads/icons/nsfs.svg " alt={'Sponsors'} />
                  <span>01</span>
                  <h4>Number of students/faculty <br /> start-ups incubated AY21-22</h4>
                </li>
                <li>
                  <img src="/uploads/icons/tnsp.svg" alt={'Institutional Collaborations'} />
                  <span>6200+</span>
                  <h4>Total number of <br />Scopus Publications </h4>
                </li>
                <li>
                  <img src="/uploads/icons/nsga.svg" alt={'Number of Research'} />
                  <span>396</span>
                  <h4>Number of Sponsored<br /> Grants Awarded </h4>
                </li>
              </ul>
            </div>
        </div>
      </section>
      <section id='partnership' className="m-wrap about-ovpr">
          <div className='container'>
          <h2 className="text-hbku-primary p-b-3">Key Partnerships</h2>
            <div className="bg-darkblue"> 
            <img className='about-ovpr-image full-width' src='/uploads/partnership.png' alt='OVRP'  />

            </div>
            </div>
        </section>
      <Footer {...footerEn} />
    </>
  )
}

export default Impact
