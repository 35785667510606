import { Link } from 'react-router-dom'

function BreadCrumb(props) {
  if (!props.crumb || props.crumb.length === 0) return <></>
  return (
    <ul className='breadcrumb'>
      {props.crumb.map((e, k) => (
        <li key={k}>
          <>
                    {e.link === '/'
          ? <a href={'https://www.hbku.edu.qa'} target={'_blank'} rel="noreferrer">{e.text}</a>
          : <Link to={e.link}>{e.text}</Link>
          } 
                    </>
          
        </li>
      ))}
    </ul>
  )
}

export default BreadCrumb
