import React, { useState } from 'react';
// import { Link } from 'react-router-dom'

import Footer from '../components/Footer'
import Navigation from '../components/Navigation'
import { footerEn } from '../data/footer'
import { navigationButtom, navigationTop } from '../params'

import '../assets/scss/VprOffice.scss'
import IRB from '../components/Compliance/IRB';

const navigationIntern = [
  { text: 'Institutional Review Board (IRB)', hash: '/research/compliance/irb', isActive: true},
  { text: 'Institutional Biosafety Committee (IBC)', hash: '/research/compliance/ibc', isActive: false},
]

const crumb = [
  { link: '/', text: 'Home' },
  { link: '/research', text: 'Research' },
  { link: '/research/compliance', text: 'Compliance' },
]


function ComplianceAtirb(props) {
  
 // const [OvprSupportShow, setOvprSupportShow] = useState("OvprSupport")
  const [OvprSupportShow, setOvprSupportShow] = useState(false);


  document.title = 'VPR Office - HBKU'
  const headerData = {
    title: 'Research at HBKU',
    crumb: crumb,
    navigationButtom: navigationButtom,
    navigationTop: navigationTop,
    applicationLinks: [{ text: 'Apply now', class: 'bordred', link: '/header/applynow' }],
  }
  return (
    <>
      <Navigation
        navigationIntern={navigationIntern}
        {...headerData}
        updateLang={props.updateLang}
      />
        <IRB />
      <Footer {...footerEn} />
    </>
  )
}

export default ComplianceAtirb
