import '../../assets/scss/Compliance.scss'

const IRB = () => {
    return(
        <>
        <section className="m-wrap about-ovpr irb-ibc">
          <div className='container'>
            <h2 className="text-hbku-primary p-b-3">Institutional Review Board (IRB)</h2>
            <p>The Hamad Bin Khalifa University Institutional Review Board (HBKU-IRB) is responsible for the ethical protection of the rights and welfare of human subjects from research risks. The IRB assures that all human subjects research activities conducted under the auspices of HBKU are ethically and methodologically sound, as required under public law and as expected by the public. </p>
            <p>The IRB is a highly professional committee that reviews and approves human research protocols of various disciplines and all related processes and documents to ensure that the rights and welfare of human subjects are protected above all other considerations. The IRB has the authority to approve, require modifications or disapprove all research activities that fall within their jurisdiction in conformity with all ethical foundations for the protection of human subjects. The IRB functions independently to protect its ethical mission without compromise and without any perception of conflict of interest or undue influence of power.</p>
           
            <p>The Ministry of Public Health (MOPH) has provided assurance to HBKU's Institutional Review Board that it will adhere to Qatari laws on <a className='text-primary' href='https://research.moph.gov.qa/en/Pages/HumanResearch.aspx' target="_target">research involving human subjects.</a> </p>
            
            <p>The HBKU colleges and research institutes, as well as any other institution that depends on HBKU-IRB via a reliance agreement, are the only entities that are subject to the HBKU-IRB's obligation for approving research proposals involving human subjects. Any joint project including research activities at locations other than the aforementioned institutes must be approved by the IRB office of those locations. Prior to beginning any research activity outside of Qatar, the activity must first receive the necessary ethical permission from the foreign nation.</p>
            
            {/* <p> <strong>Important Notice: Summer Review Delays (June-August)<br></br> The HBKU-IRB members will be traveling intermittently during the summer. As such, there will be delays in reviewing new submissions, with the review timeframe extended to approximately 5 weeks. Thank you for your understanding.</strong></p> */}
            
            <div className="l-article l-accordion l-accordion4">
                <div className="mez slider">
                    <h3 className="l-accordion__title">
                        <span>How to apply for IRB</span>
                    </h3>
                    <div className="l-accordion__content">
                        <p>For IRB applications, please refer to the “<a href="https://www.hbku.edu.qa/sites/default/files/hbku-irb_application_guidelines_and_faqs.pdf" target="_blank">IRB Application Process Guide and FAQs</a> ”.</p>
                        <p>If you already have an account, please click on this link to complete your submission : <a href="https://qbri.app.cayuse.com" target="_blank"> HBKU-IRB Cayuse</a> </p>
                        <p>To create a new account please email <a href="mailto:hbkuirb@hbku.edu.qa">hbkuirb@hbku.edu.qa </a></p>
                        <p>Click here to <a href="https://www.hbku.edu.qa/sites/default/files/end_user_irb_training-1-2-2.mp4" target="_blank">watch Cayuse system training video</a> </p>
                        <p>For renewal/or amendments (of applications approved before the date: 08 September 2021), please click on this link: <a href="https://hbku.wufoo.com/forms/qbrihbku-institutional-review-board/" target="_blank">Wufoo Submission</a></p>
                    
                        <p>If you have any questions, please send an email to <a href="mailto:hbkuirb@hbku.edu.qa">hbkuirb@hbku.edu.qa</a></p>
                    </div>
                    <h3 className="l-accordion__title">
                        <span>Guidelines</span>
                    </h3>
                    <div className="l-accordion__content">
                        <ul>
                            <li><a href="https://www.hbku.edu.qa/sites/default/files/hbku-irb_application_guidelines_and_faqs.pdf" target="_blank">HBKU-IRB Application Process Guide and FAQs </a></li>
                            <li><a href="https://www.hbku.edu.qa/sites/default/files/citi_human_subjects_research_course_enrollment_guide.pdf" target="_blank">CITI Human Subjects Research Course Enrollment Guide</a></li>

                        </ul>
                    </div>
                    <h3 className="l-accordion__title">
                        <span>HBKU-IRB Submission Documents</span>
                    </h3>
                    <div className="l-accordion__content">
                        <ul>
                        <li><a href="https://www.hbku.edu.qa/sites/default/files/informed_consent_social.docx" target="_blank">Informed consent (Social) – Eng
                        </a></li>
                        <li><a href="https://www.hbku.edu.qa/sites/default/files/hbku-irb_template_-_informed_consent_social_studies_-_ar.docx" target="_blank">Informed consent (Social) – Ar 
                        </a></li>
                        <li><a href="https://www.hbku.edu.qa/sites/default/files/hbku-irb_template_-_informed_consent_biomedical_studies_-_eng.docx" target="_blank">Informed consent (Biomedical) – Eng
                        </a></li>
                        <li><a href="https://www.hbku.edu.qa/sites/default/files/hbku-irb_template_-_informed_consent_biomedical_studies_-_ar.docx" target="_blank">Informed consent (Biomedical) – Ar</a></li>
                        <li><a href="https://www.hbku.edu.qa/sites/default/files/cover_memo_for_irb_submission_hbku-ovpr-gov-irb-form-cm-003-00.docx" target="_blank">Cover memo (New application, Amendments and Renewals via Wufoo)
                        </a></li>
                        <li><a href="https://www.hbku.edu.qa/sites/default/files/annual_continutation_request_form_hbku-ovpr-gov-irb-form-acr-003-00.docx" target="_blank">Annual continuation request form (Renewals via Wufoo)
                        </a></li>
                        <li><a href="https://www.hbku.edu.qa/sites/default/files/co-investigator_information_form_hbku-ovpr-gov-irb-form-ci-003-00.docx" target="_blank">Co-investigator form (for Wufoo applications)
                        </a></li>
                        <li><a href="https://www.hbku.edu.qa/sites/default/files/conflict_of_interest_disclosure_form_for_hbku_employees_hbku-ovpr-gov-irb-form-coi-disc-hbkuemployees-003-00.docx" target="_blank">HBKU Conflict of Interest Disclosure Form
                        </a></li>
                        <li><a href="https://www.hbku.edu.qa/sites/default/files/conflict_of_interest_disclosure_form_for_collaborators_hbku-ovpr-gov-irb-form-coi-disc-collaborators-003-00.docx" target="_blank">Collaborator Conflict of Interest Disclosure Form
                        </a></li>
                        <li><a href="https://www.hbku.edu.qa/sites/default/files/adverse_events_form.docx" target="_blank">Adverse Events Form
                        </a></li>
                        <li><a href="https://www.hbku.edu.qa/sites/default/files/delegation_of_responsibility.docx" target="_blank">Delegation of Responsibilities
                        </a></li>
                        <li><a href="https://www.hbku.edu.qa/sites/default/files/serious_adverse_events_form.docx" target="_blank">Serious Adverse Events
                        </a></li>
                        <li><a href="https://www.hbku.edu.qa/sites/default/files/hbku-irb_template_-_institutional_approval.docx" target="_blank">Institutional Approval Template</a></li>
                        <li><a href="https://www.hbku.edu.qa/sites/default/files/hbku-irb_template_-_supervisor_approval.docx" target="_blank">Supervisor Approval Template</a></li>
                        <li><a href="https://www.hbku.edu.qa/sites/default/files/hbku-irb_template_-_scientific_evaluation.docx" target="_blank">Scientific Evaluation</a></li>

                        <li><a href="https://www.hbku.edu.qa/sites/default/files/hbku-irb_template_-_permission_to_access_facility_hbku-ovpr-gov-irb-temp-fa-003-0063.docx" target="_blank">Site Authorization Template</a></li>
                        </ul>
                    </div>


                    <h3 className="l-accordion__title">
                        <span>Research Non-Compliance Overview</span>
                    </h3>
                    <div className="l-accordion__content">
                        <p>Non-compliance encompasses any deviation or failure to adhere to the local applicable laws, Ministry of Public Health (MoPH) regulations, and HBKU-IRB policies that safeguard the rights, safety, and welfare of human participants in research. 
                        </p>

                        <p>Examples of non-compliance include, but are not limited to the following: </p> 

                        <ul className="number-ul">
                            <li>Failure to obtain or renew local IRB approval prior to conducting human subject research and disseminating such findings.</li>
                            <li>Conducting research after study approval has expired, without obtaining the renewal.</li>
                            <li>Deviation from the approved research protocol.</li>
                            <li>Overenrollment of participants beyond the number authorized by the IRB.</li>
                            <li>Implementing unauthorized changes to the protocol without IRB approval.</li>
                            <li>Non-compliance with consent procedures, such as using participants’ data other than what is outlined in the consent form.</li>
                            <li>Inadequate reporting of adverse events to the HBKU-IRB or appropriate authorities.</li>
                            <li>Inaccurate or incomplete record-keeping.</li>
                            <li>Failure to disclose a conflict of interest, as it may bias the research or its outcomes.</li>
                        </ul><br></br>

                        <h5>Types of Non-Compliance</h5><br></br>

                        <ul className="number-ul">
                            <li><strong>Serious Non-Compliance:</strong>  Actions that increase risk to participants, affect their rights and well-being, or compromise the scientific integrity of the research.</li>
                            <li><strong>Continuing Non-Compliance:</strong>  Repeated non-compliance across one or more studies under an individual Principal Investigator (PI), indicating a pattern that can impact participants or research validity.</li>
                        </ul><br></br>
                        
                        <h5>Reporting Non-Compliance</h5>
                        <p>Researchers must promptly report any non-compliance to the HBKU-IRB. This can include self-reporting or reports from participants, staff, or oversight authorities. Reporting can be done via email to the HBKU-IRB Office.</p>

                        <h5>Documentation of Non-Compliance</h5>
                        <p>When self-reporting non-compliance, the Principal Investigator (PI) or research team must submit to the IRB a written summary detailing the following:</p>
                        
                        <ul className="number-ul">
                            <li>A summary of the non-compliance </li>
                            <li>The outcome and effect on the research </li>
                            <li>The measures being taken to prevent recurrence.</li>
                        </ul>
                         
                        <p>This report should be submitted using a Protocol Deviation Report via a Cover Memo, which can be found on the HBKU Research Compliance webpage.</p> 
                          
                        <h5>IRB Review and Actions</h5>
                        <p>Upon receiving a non-compliance report, the IRB will assess its nature and severity, classifying it as general, serious, or continuing non-compliance based on definitions in the policy. The IRB also considers potential risks to participants and any mitigating factors.</p>

                        <p>If it's classified as general non-compliance, it can be reviewed via expedited review by the Chair or an appointed reviewer. For serious or continuing non-compliance, a full-board review is required, and the Principal Investigator may need to attend the IRB meeting. The IRB may conduct further investigations and may request the PI’s presence to clarify the situation and determine if corrective actions are necessary.</p>

                        <p>The IRB reviews reports of non-compliance and may impose corrective actions, such as:</p>

                        <ul> 
                            <li>Study-specific corrections</li>
                            <li>Education for the research team</li>
                            <li>Modifications to protocols or consent forms</li>
                            <li>Re-consenting of participants</li>
                            <li>Suspension or termination of the study</li>
                            <li>Enhanced monitoring of the study</li>
                        </ul>
                        <br></br> 
                        <h5>Suspension or Termination of IRB Approval</h5>
                        <p>The IRB can suspend or terminate a study if non-compliance occurs, especially if it leads to increased risks for participants. Affected participants may be contacted for additional safety measures or withdrawal from the study. The IRB's decision will be communicated to the Principal Investigator and institutional officials.</p>
 
                         
                    </div>

                    <h3 className="l-accordion__title">
                        <span>Review Timeframes</span>
                    </h3>
                    <div className="l-accordion__content">

                    <h5>Our Commitment to Quality and Efficiency </h5>
                    <p>The HBKU-IRB is committed to protecting the rights and welfare of human research participants, ensuring compliance with all applicable ethical and legal requirements in Qatar according to the Ministry of Public Health and international guidelines. </p>
                    
                    <p>We strive to process submissions promptly while maintaining thoroughness and fairness. However, processing times may vary depending on submission volume and review complexity. </p>
                    
                    <h5>Estimated Timeframes for <span className="underline" >Initial Feedback</span>  </h5>
                    <ul>
                        <li><strong>Non-peak period:</strong>  Initial pre-review feedback typically may take <strong>7-10 business days</strong> from the date of initial submission. </li>
                        <li><strong>Peak Periods:</strong>  During high-volume submission periods, such as the spring and fall semester deadlines or during the summer and winter breaks and public holidays, initial processing may extend to <strong>3–4 weeks</strong>.</li>
                    </ul>

                     <h5>Estimated Timeframes for <span className="underline" >Committee Review</span> </h5>
                    <ul>
                        <li><strong>Non-peak period:</strong>  Committee review typically takes <strong>14 business days</strong> from the date of assigning the submission to an IRB member. </li>
                        <li><strong>Peak Periods:</strong>  During high-volume submission periods, such as the spring and fall semester deadlines or during the summer and winter breaks and public holidays, reviews by the committee may extend to <strong>4–6 weeks</strong>.</li>
                        <li><strong>Expedited and Full Board Reviews:</strong>  Submissions requiring additional considerations, such as full board reviews, may require more time based on scheduled meetings and agenda capacity. </li>
                    </ul>

                     <h5>Estimated Timeframes for <span className="underline" >PI Revisions</span> </h5>
                    <ul>
                        <li><strong>Pre-review comments:</strong>  Submissions will undergo a thorough pre-review to ensure that all submissions are complete and error free. Should there be any missing information or errors that need correcting, submissions will be returned to PIs. You will have <strong>7-10 days</strong> to make any necessary corrections to your submissions. Corrections of a more complex nature may require additional time to correct. Please contact the IRB Office if additional time is required to make the necessary changes. </li>
                        <li><strong>Reviewer comments:</strong>  Committee members may request additional modifications and you will be given a further <strong>7-10 days</strong> to make any necessary modifications according to their comments. </li>
                    </ul>

                    <h5>High-Volume Deadlines </h5>
                    <p>To ensure fairness and efficiency: </p>

                    <ul>
                        <li>Submissions for time-sensitive projects e.g., graduating student research must be received by the following deadlines <br></br><br></br>
                        
                            <ul>
                                <li><strong> Spring graduating students: 31<sup>st</sup> October </strong></li>
                                <li><strong> Fall graduating students: 30<sup>th</sup> April </strong></li>
                            </ul>
                        
                        </li>
                        <li>We process applications on a <strong><span className="underline" >first-come</span>, <span className="underline" >first-served basis</span></strong>. Late submissions may not be reviewed in time for upcoming milestones. </li>
                    </ul>

                    <h5>Time to approval </h5>
                    <ul>
                        <li><strong>Non-peak periods: </strong> We aim to approve the following review category submissions within the below timeframes during non-peak periods: <br></br><br></br>
                             <ul>
                                <li><strong> Exempt category submission: </strong> approximately <strong>3-4 weeks</strong> from the date of submission of a complete application </li>
                                <li><strong> Expedited category submissions: </strong> approximately <strong>5-6 weeks</strong> from the date of submission of a complete application  </li>
                                <li><strong> Full board category submissions: </strong> approximately <strong>7-8 weeks</strong> from the date of submission of a complete application. *Full-Board Meeting are subject to change around holidays or when quorum is not met.   </li>
                            </ul>
                        </li>
                        <li><strong>Peak Periods:</strong> We aim to approve the following review category submissions within the below timeframes during peak periods: <br></br><br></br>
                            <ul>
                                <li><strong> Exempt category submission: </strong> approximately <strong>6-8 weeks</strong> from the date of submission of complete application  </li>
                                <li><strong> Expedited category submissions: </strong> approximately <strong>10-12 weeks</strong> from the date of submission of a complete application   </li>
                                <li><strong> Full board category submissions: </strong> approximately <strong>14-16 weeks</strong> from the date of submission of a complete application. *Full-Board Meeting are subject to change around holidays or when quorum is not met. </li>
                            </ul>
                        </li>
                    </ul>

                    <p><i>PLEASE NOTE THESE TIMEFRAMES FACTOR IN TIME FOR PI REVISIONS WHICH ARE REQUIRED FOR THE MAJORITY OF SUBMISSIONS. DELAYS AND INCOMPLETE SUBMISSION OF REVISIONS WILL RESULT IN DELAYS IN APPROVING SUBMISSIONS.</i></p>

                     <h5>Communication and Updates </h5>
                     <ul>
                        <li>You will receive a confirmation email upon submission. This confirms that your application is under review. </li>
                        <li><strong> Status Updates: </strong> Applicants are encouraged to check their registered email for updates. <span className="underline" >Please refrain from contacting the IRB office for status enquiries within the published timeframes</span> , <span className="underline" >as this may delay overall processing</span>. </li>
                        <li><strong> In Case of Delays: </strong> If processing exceeds our estimated timeframes, we will notify you via email.  </li>
                    </ul>

                     <h5>How You Can Help Expedite the Process</h5>
                     <ul>
                        <li><strong> Submit Early: </strong> Wherever possible, submit your application well ahead of deadlines to allow sufficient time for review. </li>
                        <li><strong> Ensure Completeness: </strong> Double-check your submission for completeness and compliance with guidelines. Student applicants should ensure that their supervisors and co-PI all conduct a thorough review of their applications and certify them prior to submission. Incomplete submissions will be returned for revisions, causing delays. </li>
                        <li><strong> Submissions awaiting certification: </strong> Please ensure that PIs and co-PIs all successfully certify submissions. Submissions which have Not been successfully certified by the PI and co-PIs will Not be received by the IRB Office. Kindly ensure this step is completed in order for your submission to be successfully received.</li>
                    </ul>
                    
                    </div>
                </div>
            </div>

            {/* <h2 className="text-hbku-primary p-b-2 p-t-2 m-t-3">Research Non-Compliance</h2>
            <p>Non-compliance encompasses any deviation or failure to adhere to the local applicable laws, Ministry of Public Health (MoPH) regulations, and HBKU-IRB policies that safeguard the rights, safety and welfare of human participants in research.</p>
             
            <div className="l-article l-accordion l-accordion4">
                <div className="mez slider">
                    <h3 className="l-accordion__title">
                        <span>Examples of non-compliance</span>
                    </h3>
                    <div className="l-accordion__content">
                        1112222
                    </div>
                    <h3 className="l-accordion__title">
                        <span>Types of Non-Compliance</span>
                    </h3>
                    <div className="l-accordion__content">
                        2222222
                    </div>
                    <h3 className="l-accordion__title">
                        <span>Reporting Non-Compliance</span>
                    </h3>
                    <div className="l-accordion__content">
                        3333
                    </div>
                    <h3 className="l-accordion__title">
                        <span>Documentation of Non-Compliance</span>
                    </h3>
                    <div className="l-accordion__content">
                        444
                    </div>
                    <h3 className="l-accordion__title">
                        <span>IRB Review and Actions</span>
                    </h3>
                    <div className="l-accordion__content">
                        555
                    </div>
                    <h3 className="l-accordion__title">
                        <span>Suspension or Termination of IRB Approval</span>
                    </h3>
                    <div className="l-accordion__content">
                        666
                    </div>
                </div>
            </div> */}


            <h3 className="text-hbku-primary p-b-2 p-t-2 m-t-3">HBKU-IRB Members</h3>
            <p>HBKU-IRB members include scientists, non-scientists, physicians, ethicists, and individuals with expertise in allied disciplines. The HBKU-IRB is structured as follows: </p>
            <div className="filter-container">
                <table className="views-table full-width">
                    <thead>
                        <tr>
                            <th className="f-project-title">Name </th>
                            <th className="f-project-lead">Entity </th>
                            <th className="f-project-entity">Role </th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr className="odd">
                          <td>Dr. Dena Al-Thani</td>
                          <td>HBKU CSE</td>
                          <td>Chair</td>
                      </tr>
                    <tr className="even">
                          <td>Dr. Pablo Rodriguez del Pozo</td>
                          <td>Weill Cornell Medicine-Qatar</td>
                          <td>Co-Chair</td>
                      </tr>
                         <tr className="odd">
                            <td>Dr. Abdolmehdi Riazi</td>
                            <td>HBKU CHSS</td>
                            <td>Co-Chair</td>
                        </tr>
                         <tr className="even">
                            <td>Dr. Khalid Al Ali</td>
                            <td>Ministry of Education and Higher Education</td>
                            <td>Member</td>
                        </tr>
  
                         {/* <tr className="odd">
                            <td>Dr. Tareq Al-Ansari</td>
                            <td>HBKU CSE</td>
                            <td>Member</td>
                        </tr> */}
                        <tr className="odd">
                            <td>Dr. Asmaa Al-Fadala</td>
                            <td>HBKU CPP</td>
                            <td>Member</td>
                        </tr>
                        {/* <tr className="even">
                            <td>Dr. Fouad Al-Shaban</td>
                            <td>HBKU QBRI</td>
                            <td>Member</td>
                        </tr> */}
                        <tr className="even">
                            <td>Dr. Mohammad Ghaly</td>
                            <td>HBKU CIS</td>
                            <td>Member</td>
                        </tr>
                        <tr className="odd">
                            <td>Dr. Muna Al-Tamimi</td>
                            <td>Hamad Medical Corporation</td>
                            <td>Member</td>
                        </tr>
                        <tr className="even">
                            <td>Dr. Mohamed Emara</td>
                            <td>Qatar University</td>
                            <td>Member</td>
                        </tr>
                        <tr className="odd">
                            <td>Dr. George Nemer</td>
                            <td>HBKU CHLS</td>
                            <td>Member</td>
                        </tr>
  
                      
                    </tbody>
                </table>
            </div>
            <br /> <br />
            <h3 className="text-hbku-primary p-b-2 p-t-2">IRB Meetings 2025</h3>
            <p>IRB meetings typically take place on the last Tuesday of every month.</p>
           {/* <div className="filter-container">
                <table className="views-table full-width">
                    <thead>
                        <tr>
                            <th className="f-project-title">Month </th>
                            <th className="f-project-lead">Submission Deadline </th>
                            <th className="f-project-entity">Meeting Dates </th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr className="even">
                        <td>April</td>
                        <td className="text-center">Ramadan and Eid*</td>
                        <td> &nbsp; </td>
                    </tr>
                    <tr className="odd">
                        <td>May</td>
                        <td>16 May</td>
                        <td>30 May</td>
                    </tr>
                    <tr className="even">
                        <td>June</td>
                        <td>13 June</td>
                        <td>27 June</td>
                    </tr>
                    <tr className="odd">
                        <td>July</td>
                        <td>11 July</td>
                        <td>25 July</td>
                    </tr>
                    <tr className="even">
                        <td>August</td>
                        <td>15 August</td>
                        <td>29 August</td>
                    </tr>
                    <tr className="odd">
                        <td>September</td>
                        <td>12 September</td>
                        <td>26 September</td>
                    </tr>
                    <tr className="even">
                        <td>October</td>
                        <td>17 October</td>
                        <td>31 October</td>
                    </tr>
                    <tr className="odd">
                        <td>November</td>
                        <td>14 November</td>
                        <td>28 November</td>
                    </tr>
                    <tr className="even">
                        <td>December</td>
                        <td>5 December</td>
                        <td>19 December</td>
                    </tr>
                    </tbody>
                </table>
                <br></br>
            </div> */}
            <p><strong>Kindy be advised: </strong> IRB submissions are reviewed on a first-come, first-served basis. Complete applications must be submitted by the “Submission Deadline” for consideration at the corresponding meeting. Assignment to an agenda is not automatic and may vary depending on various factors, including the quality and completeness of the submission, complexity of the project, requirement of additional reviews and responsiveness of the researcher to the requests from the IRB Coordinator. Meeting dates are tentative upon achieving quorum for each meeting scheduled.</p>
            
          </div>
        </section>
        </>
    )
}

export default IRB;