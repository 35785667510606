import Navigation from '../components/Navigation'
import Footer from '../components/Footer'
import { footerEn /* footerAr*/ } from '../data/footer'
import { navigationTop, navigationButtom } from '../params'
import '../assets/scss/NewsDetails.scss'
const navigationIntern = [
  // { text: 'Funding', hash: '#funding-list' },
  { text: 'News Details', hash: '#news-details' },
  // { text: 'Filter News', hash: '#filter-news' },
  
]

const crumb = [
  { link: '/', text: 'Home' },
  { link: '/research', text: 'Research' },
  { link: '/research/news-details', text: 'News Details' },
]


function ResearchHighlightsDetail(props) {
  document.title = 'Research News Details - HBKU'
  const headerData = {
    title: 'Research at HBKU',
    crumb: crumb,
    navigationButtom: navigationButtom,
    navigationTop: navigationTop,
    applicationLinks: [{ text: 'Apply now', class: 'bordred', link: '/header/applynow' }],
  }
  return (
    <>
      <Navigation
        navigationIntern={navigationIntern}
        {...headerData}
        updateLang={props.updateLang}
      />
     
      <section id='news-details' className="m-wrap news-details">
          <div className="mez news-details-content">
						<div className="mez news-details-head">
              <div className='container'>
                  <div className="row">
                    <div className="col-12 col-md-9  padding-right-40">
                      <div className="news-details-head__left">
                        <ul className="breadcrumb">
                          <li><a href="/">Home</a></li>
                          <li><a href="/research">Research</a></li>
                          <li>Research Projects</li>
                      </ul>
                      <h1>COVIS-19 Policy Tracker: MENA Government <br /> Responses to the Crisis</h1>
                      </div>
                    </div>
                    <div className="col-12 col-md-3">
                      <div className="news-details-head__right">
                        <div className="dateinfo">
                          <h5><span> Publishing Date:</span>  24 Sep 2021 </h5>
                        </div>
                        <div className="shareBtn"> <a href="/" className="more"> <i className="fas fa-share"></i> Share </a></div>
                      </div>
                    </div>
                  </div>
							</div>
						</div>

            <div className="news-details-body m-wrap">
              <div className='container'>
                <div className="row">
                  <div className="col-12 col-md-7">
                    <div className="card-block">
                      <div className="project-details">
                        <h3>Project Details</h3>
                        <ul>
                          <li><span>Project Start Date: </span> May 2021 </li>
                          <li><span>Project End Date: </span> September 2021 </li>
                          <li><span>Funding Source: </span> External </li>
                        </ul>
                      </div>
                    </div>
                    <div className="card-block">
                        <div className="project-summary">
                           <h3>Project Summary</h3>
                           <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. </p>
                           <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. </p>
                        </div>
                    </div>
                   {/* <div className="card-block">
                        <div className="project-summary">
                           <h3>Photos, schematics, graphs.....etc.</h3>
                        </div>
                    </div> */}
                    <div className="card-block">
                        <div className="project-summary">
                           <h3>Project Objectives</h3>
                           <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris</p> <p> nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. </p>
                           <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. </p>
                        </div>
                    </div>
                    <div className="card-block">
                        <div className="project-summary">
                           <h3>Up-to-date achievements</h3>
                           <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. </p>
                        </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-5">
                    <div className="card-block">
                      <div className="project-investigator"> 
                        <h3>Lead Principal Investigator</h3>
                        <div className="block-profile">
                          <ul className="block-profile__list">
                              <li className="block-profile__list--item">
                                  <div className="profile--img">
                                      <img src="/uploads/andreas-img.jpg" alt="" />
                                  </div>
                                  <div className="profile-details">
                                      <h4 className="person-name">Dr. Andreas Rechkemmer</h4>
                                      <div className="person-designation"><a href="/"> Professor </a></div>
                                      <div className="person-col"><a href="/">College of Public Policy </a></div>
                                  </div>
                              </li>
                          </ul>
                      </div>
                      </div>
                    </div>
                    <div className="card-block">
                      <div className="project-investigator">
                         <h3>Other Pls</h3>
                         <div className="block-profile">
                          <ul className="block-profile__list">
                              <li className="block-profile__list--item">
                                  <div className="profile--img">
                                      <img src="/uploads/anis-img.jpg" alt="" />
                                  </div>
                                  <div className="profile-details">
                                      <h4 className="person-name">Dr. Anish B. Brik</h4>
                                      <div className="person-designation"><a href="/"> Professor </a></div>
                                      <div className="person-col"><a href="/">College of Public Policy </a></div>
                                  </div>
                              </li>
                              <li className="block-profile__list--item">
                                  <div className="profile--img">
                                      <img src="/uploads/ozcan-img.jpg" alt="" />
                                  </div>
                                  <div className="profile-details">
                                      <h4 className="person-name">Dr. Ozcan Ozturk</h4>
                                      <div className="person-designation"><a href="/"> Professor </a></div>
                                      <div className="person-col"><a href="/">College of Public Policy </a></div>
                                  </div>
                              </li>
                              <li className="block-profile__list--item">
                                  <div className="profile--img">
                                      <img src="/uploads/leslie-img.jpg" alt="" />
                                  </div>
                                  <div className="profile-details">
                                      <h4 className="person-name">Dr. Leslie Pal</h4>
                                      <div className="person-designation"><a href="/"> Dean </a></div>
                                      <div className="person-col"><a href="/">College of Public Policy </a></div>
                                  </div>
                              </li>
                              <li className="block-profile__list--item">
                                  <div className="profile--img">
                                      <img src="/uploads/andreas-img.jpg" alt="" />
                                  </div>
                                  <div className="profile-details">
                                      <h4 className="person-name">Dr. Andreas Rechkemmer</h4>
                                      <div className="person-designation"><a href="/"> Professor </a></div>
                                      <div className="person-col"><a href="/">College of Public Policy </a></div>
                                  </div>
                              </li>
                          </ul>
                      </div>
                      </div>
                    </div>
                    <div className="card-block">
                      <div className="project-video">
                        <h3>Video</h3>
                        <div className="cl2019quote-info__video">
                        <video controls="controls" muted loop controlslist="nodownload"
                          poster="https://www.hbku.edu.qa/sites/default/files/video-poster/edx_sustainability_and_major_sports_events.jpg" className="full-width">
                          <source
                            src="https://www.hbku.edu.qa/sites/default/files/videos/edx_sustainability_and_major_sports_events_tw_1280x720.mp4"
                            type="video/mp4" className="full-width" />
                        </video>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </section>
       
      
      <Footer {...footerEn} />
    </>
  )
}

export default ResearchHighlightsDetail
