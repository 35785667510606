import '../assets/scss/Articles.scss'

//const pad = n => n.toString().padStart(2, '0')

function Articles(props) {
  const inverse = props.inverse ? 1 : 0
  return (
    <section dir={props.lang === 'ar' ? 'rtl' : 'ltr'} className='articles' >
      <div className='container'>
        {props.data.map((e, k) => (
          <div id={e.hash} className='row' key={k}>
            <div className={`p-b-2 col-12 col-lg-6${(k + inverse) % 2 === 0 ? ' order-1' : ''}`}>
              <img src={e.image} alt={e.title} />
            </div>
            <div className={`p-b-2 col-12 col-lg-6${(k + inverse) % 2 === 0 ? ' order-0' : ''}`}>
              {/* <span className='number'>{pad(e.number)}</span> */}
              {e.title && <h3>{e.title}</h3>}
              {e.subtitle && <p className=''>{e.subtitle}</p>}
              {e.paragraphs.map((el, ke) => (
                <p key={ke}>{el}</p>
              ))}
              {e.more && <a href={e.link} target={'_blank'} rel="noreferrer" className='more'>{e.more}</a>}
              {e.research_link ? (<a href={e.research_link} className='research' target={'_blank'} rel="noreferrer" >{e.research}</a>):('')}
              
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default Articles
